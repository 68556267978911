import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  Group,
  Input,
  ModalCard,
  ModalRoot,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import {
  Icon24Add,
  Icon24DeleteOutline,
  Icon24ListDeleteOutline,
} from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import { PaginationApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import {
  countUniqueValues,
  createStringFromObjArray,
} from 'lib/utils/arrayOfObjects';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import TableSetup from 'components/common/TableSetup';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import { genreFilters, genreHeaders } from './constants.genresDirectory';
import { IGenre } from './types.genresDirectory';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';
import { ChipsSelectCategories } from 'components/common/ChipsSelectCategories';

const GenresDirectory = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const [genres, setGenres] = useState<IGenre[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { loading: deleteLoading, fetchData: deleteFetch } = useFetch();
  const { data, loading, fetchData } = useFetch<PaginationApiResponse<IGenre[]>>();

  const {
    checkedElements,
    handleCheckedItem,
    handleToggleAllCheckValue,
    clearCheckedElements,
  } = useCheckedElements<IGenre>();

  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(genreFilters);

  const { q, category_id, is_active } = filters;

  const handleDeleteGenre = async () => {
    const deletePromises = checkedElements.map((id) =>
      deleteFetch({
        method: 'delete',
        path: ENDPOINTS.genresId(id),
        onSuccessMessage: 'Успешно удалено',
      }),
    );
    await Promise.all(deletePromises);
    await setIsDeleteModalOpen(false);
    await fetchListFunction();
    await clearCheckedElements();
  };

  const fetchListFunction = () => {
    const queryParams = parseFiltersUrl(location.search) ?? ''

    fetchData({
      method: 'get',
      path: ENDPOINTS.genres(),
      params: {
        page: currentPage - 1,
        ...QueryString.parse(queryParams),
      }
    });
  };

  const handleResetFilters = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setGenres(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem(
      'columnOrder_directories_genres',
    );

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(genreHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            {checkedElements.length ? (
              <Button
                onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                className="vkui-edit-button-error min-w-fit"
                style={{ backgroundColor: '#E64646' }}
                size="m"
                before={<Icon24DeleteOutline />}
              >
                Удалить
              </Button>
            ) : (
              <Link to={ADMIN_DIRECTORIES_ROUTE + `/genres/addNew`}>
                <Button
                  mode="primary"
                  className="vkui-edit-button-primary min-w-fit"
                  size="m"
                  before={<Icon24Add />}
                >
                  Добавить
                </Button>
              </Link>
            )}
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="directories_genres"
              headers={genreHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters ? (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Жанр">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="q"
                  placeholder="Введите название жанра"
                  value={q as string}
                  onChange={handleChange}
                />
              </FormItemPanel>
              <ChipsSelectCategories
                idFromQuery={category_id}
                setFilters={setFilters}
              />
              <FormItemPanel top="Активность" className="p-0">
                <Select
                  className="vkui-select"
                  name="is_active"
                  placeholder="Выберите активность"
                  value={String(is_active)}
                  onChange={handleChange}
                  options={[
                    { label: 'Активно', value: 'true' },
                    { label: 'Неактивно', value: 'false' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          ) : null}
          {genres.length > 0 ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      <th>
                        <Checkbox
                          checked={checkedElements.length === genres.length}
                          onChange={() => handleToggleAllCheckValue(genres)}
                        />
                      </th>
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {genres.map(({ id, name, categories, is_active }) => {
                      return (
                        <tr
                          key={id}
                          className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          onClick={() =>
                            window.open(
                              ADMIN_DIRECTORIES_ROUTE + `/genres/edit/${id}`,
                              '_blank',
                            )
                          }
                        >
                          {checkedElements && (
                            <td
                              className="py-2"
                              onClick={(event) => event.stopPropagation()}
                            >
                              <Checkbox
                                checked={checkedElements.includes(id)}
                                onChange={() => handleCheckedItem(id)}
                              />
                            </td>
                          )}

                          {activeHeaders.some((h) => h.value === 'name') && (
                            <td className="py-2" id="name">
                              <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                {name}
                              </Text>
                            </td>
                          )}

                          {activeHeaders.some(
                            (h) => h.value === 'category',
                          ) && (
                              <td className="py-2" id="category">
                                {categories.length > 1 ? (
                                  <TextTooltip
                                    className="max-w-lg"
                                    text={createStringFromObjArray(
                                      categories,
                                      'name',
                                    )}
                                  >
                                    <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                      {categories[0]?.name}{' '}
                                      {countUniqueValues(categories, 'name') > 1
                                        ? `+${countUniqueValues(categories, 'name') - 1}`
                                        : ''}
                                    </Text>
                                  </TextTooltip>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {categories[0]?.name || '-'}
                                  </Text>
                                )}
                              </td>
                            )}

                          {activeHeaders.some(
                            (h) => h.value === 'is_active',
                          ) && (
                              <td className="py-2" id="is_active">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {is_active ? 'Активно' : 'Неактивно'}
                                </Text>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={genres}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Жанры не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
      {isDeleteModalOpen && (
        <ModalRoot activeModal="deleteModal">
          <ModalCard
            id="deleteModal"
            onClose={() => setIsDeleteModalOpen(false)}
            className='fixed'
          >
            {deleteLoading ? (
              <Div className="p-0" style={{ width: '400px', height: '156px' }}>
                <Spinner size="medium" className="spinner" />
              </Div>
            ) : (
              <Div className="p-0 flex flex-col gap-2">
                <Title className="text-color-black" level="2">
                  Удаление жанра
                </Title>
                <Text className="text-color-gray-600">
                  Будут удалены выбранные жанры
                </Text>
                <ButtonGroup className="pt-3" align="right" stretched>
                  <Button
                    className="vkui-edit-button-secondary"
                    mode="secondary"
                    size="m"
                    onClick={() => setIsDeleteModalOpen(false)}
                  >
                    Отмена
                  </Button>
                  <Button
                    className="vkui-edit-button-primary"
                    mode="primary"
                    size="m"
                    onClick={() => handleDeleteGenre()}
                  >
                    Удалить
                  </Button>
                </ButtonGroup>
              </Div>
            )}
          </ModalCard>
        </ModalRoot>
      )}
    </>
  );
};

export default GenresDirectory;
