import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Checkbox,
  Div,
  Group,
  Placeholder,
  Select,
  Spinner,
  Input,
} from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon24ListDeleteOutline } from '@vkontakte/icons';
import {
  ADMIN_EVENTS_ROUTE,
  dataEnricherOptions,
  dataOwnerOptions,
  dataProviderOptions,
} from 'lib/constants';
import { PaginationApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import { ITableHeader } from 'lib/types';
import { IEvent, IEventsProps } from './types.events';
import { eventFilters, eventHeaders } from './constants.events';
import TableSetup from 'components/common/TableSetup';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import DateRangePicker from 'components/common/DateRangePicker';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import clsx from 'clsx';
import { Table } from 'components/common/Table';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';
import { ChipsSelectCategories } from 'components/common/ChipsSelectCategories';
import { ChipsSelectCities } from 'components/common/ChipsSelectCities';
import { ChipsSelectPlaces } from 'components/common/ChipsSelectPlaces';
import { ChipsSelectLabels } from 'components/common/ChipsSelectLabels';

const Events: FC<IEventsProps> = ({
  onCheckboxesChange,
  onSelectedEventsChange,
  action,
  pageSize = 10,
  absolutePositionedFilters,
}) => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<PaginationApiResponse<IEvent[]>>();
  const location = useLocation();
  const [events, setEvents] = useState<IEvent[]>([]);
  const { checkedElements, setCheckedElements, handleCheckedItem } =
    useCheckedElements<IEvent>();

  const [selectedEvents, setSelectedEvents] = React.useState<IEvent[]>([]);

  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(eventFilters);

  const {
    q,
    preview_url_exists,
    description_exists,
    seances_exists,
    is_enabled,
    city_id,
    place_id,
    category_id,
    provider,
    label_top_id,
    label_bottom_id,
    source_owner,
    data_enricher,
    changed_from_admin,
    is_pushkin_card,
    event_provider_id,
  } = filters;

  const [startDatetimeFrom, setStartDatetimeFrom] = useState<Date | undefined>(
    undefined,
  );

  const [startDatetimeTo, setStartDatetimeTo] = useState<Date | undefined>(
    undefined,
  );

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilters({ ...filters, q: value });
  };

  const fetchListFunction = () => {
    const queryParams = parseFiltersUrl(location.search) ?? ''

    fetchData({
      method: 'get',
      path: ENDPOINTS.events(),
      params: {
        page_size: pageSize,
        page: currentPage - 1,
        ...QueryString.parse(queryParams),
      }
    });
  };

  const handleResetFilters = useCallback(() => {
    reset();
    setStartDatetimeFrom(undefined);
    setStartDatetimeTo(undefined);
  }, [reset, setStartDatetimeFrom, setStartDatetimeTo]);

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setEvents(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    onCheckboxesChange?.(checkedElements);
  }, [checkedElements]);

  useEffect(() => {
    setCheckedElements([]);
  }, []);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_events');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(eventHeaders);
    }
  }, []);

  useEffect(() => {
    onSelectedEventsChange?.(selectedEvents);
  }, [selectedEvents]);

  const toggleEvent = React.useCallback(
    (event: IEvent) => {
      if (selectedEvents.find(({ id }) => id === event.id)) {
        setSelectedEvents((prev) => prev.filter(({ id }) => id !== event.id));
      } else {
        setSelectedEvents((prev) => [...prev, event]);
      }
    },
    [selectedEvents],
  );

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="events"
              headers={eventHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
            {action}
          </Div>
          {openFilters && (
            <div className={clsx(absolutePositionedFilters && 'relative')}>
              <FiltersWrapper
                reset={handleResetFilters}
                addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
                className={clsx(
                  absolutePositionedFilters &&
                  'absolute left-0 top-0 z-10 w-full bg-white shadow-lg rounded-md',
                )}
              >
                <FormItemPanel top="ID мероприятия у поставщика">
                  <Input
                    onKeyDown={(event) => {
                      handleKeyDown(event, addFiltersAndUpdateUrl);
                    }}
                    className="vkui-input"
                    name="event_provider_id"
                    placeholder="Укажите id"
                    value={event_provider_id as string}
                    onChange={handleChange}
                    before={<Icon16SearchOutline fill="#99A2AD" />}
                  />
                </FormItemPanel>
                <ChipsSelectCategories
                  idFromQuery={category_id}
                  setFilters={setFilters}
                />
                <ChipsSelectCities
                  filterKey="city_id"
                  idFromQuery={city_id}
                  setFilters={setFilters}
                />
                <ChipsSelectPlaces
                  idFromQuery={place_id}
                  setFilters={setFilters}
                />
                <FormItemPanel top="Обложка" htmlFor="preview_url_exists">
                  <Select
                    className="vkui-select"
                    id="preview_url_exists"
                    name="preview_url_exists"
                    placeholder="Наличие обложки"
                    value={preview_url_exists as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Есть' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Описание" htmlFor="description_exists">
                  <Select
                    className="vkui-select"
                    id="description_exists"
                    name="description_exists"
                    placeholder="Наличие описания"
                    value={description_exists as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Есть' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Сеансы" htmlFor="seances_exists">
                  <Select
                    className="vkui-select"
                    id="seances_exists"
                    name="seances_exists"
                    placeholder="Наличие сеансов"
                    value={seances_exists as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Есть' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Статус" htmlFor="is_enabled">
                  <Select
                    className="vkui-select"
                    id="is_enabled"
                    name="is_enabled"
                    placeholder="Укажите статус"
                    value={is_enabled as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Активно' },
                      { value: 'false', label: 'Неактивно' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Поставщик" htmlFor="provider">
                  <Select
                    className="vkui-select"
                    id="provider"
                    name="provider"
                    placeholder="Выберите поставщика"
                    value={provider as string}
                    onChange={handleChange}
                    allowClearButton
                    options={dataProviderOptions}
                  />
                </FormItemPanel>
                <ChipsSelectLabels
                  idFromQuery={label_top_id}
                  setFilters={setFilters}
                  type={'event'}
                />
                <ChipsSelectLabels
                  idFromQuery={label_bottom_id}
                  setFilters={setFilters}
                  type={'price'}
                />
                <FormItemPanel top="Владелец данных" htmlFor="source_owner">
                  <Select
                    className="vkui-select"
                    id="source_owner"
                    name="source_owner"
                    placeholder="Выберите владельца"
                    value={source_owner as string}
                    onChange={handleChange}
                    allowClearButton
                    options={dataOwnerOptions}
                  />
                </FormItemPanel>
                <FormItemPanel top="Обогащение данных" htmlFor="data_enricher">
                  <Select
                    className="vkui-select"
                    id="data_enricher"
                    name="data_enricher"
                    placeholder="Выберите источник"
                    value={data_enricher as string}
                    onChange={handleChange}
                    allowClearButton
                    options={dataEnricherOptions}
                  />
                </FormItemPanel>
                <FormItemPanel
                  top="Изменено админом"
                  htmlFor="changed_from_admin"
                >
                  <Select
                    className="vkui-select"
                    id="changed_from_admin"
                    name="changed_from_admin"
                    placeholder="Выберите вариант"
                    value={changed_from_admin as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Да' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Пушкинская карта" htmlFor="is_pushkin_card">
                  <Select
                    className="vkui-select"
                    id="is_pushkin_card"
                    name="is_pushkin_card"
                    placeholder="Выберите вариант"
                    value={is_pushkin_card as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Да' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <div className="z-10">
                  <DateRangePicker
                    title={'Время начала сеансов'}
                    withTime={true}
                    from={startDatetimeFrom}
                    setFrom={setStartDatetimeFrom}
                    fromFilterKey={'start_datetime__from'}
                    to={startDatetimeTo}
                    setTo={setStartDatetimeTo}
                    toFilterKey={'start_datetime__to'}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </div>
              </FiltersWrapper>
            </div>
          )}
          {events && events.length ? (
            <>
              <Table>
                <Table.Head>
                  {(onCheckboxesChange || onSelectedEventsChange) && <th></th>}
                  {activeHeaders.map((header) => (
                    <Table.HeadCell
                      key={header.value}
                    >
                      {header.label}
                    </Table.HeadCell>
                  ))}
                </Table.Head>
                <Table.Body>
                  {events &&
                    events.map((event) => {
                      const {
                        id,
                        name,
                        description,
                        preview_url,
                        category,
                        places,
                        is_enabled,
                        rating,
                        seances,
                        provider,
                        label_top,
                        label_bottom,
                        source_owner,
                        data_enricher,
                        changed_from_admin,
                        is_pushkin_card,
                      } = event;

                      return (
                        <Table.Row
                          key={id}
                          link={ADMIN_EVENTS_ROUTE + `/edit/${id}`}
                        >
                          {(onCheckboxesChange || onSelectedEventsChange) && (
                            <Table.Cell
                              paddingY={1}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <Checkbox
                                checked={checkedElements.includes(id)}
                                onChange={() => {
                                  toggleEvent(event);
                                  handleCheckedItem(id);
                                }}
                              />
                            </Table.Cell>
                          )}
                          {activeHeaders.some((h) => h.value === 'name') && (
                            <Table.Cell id="name">
                              <Table.Text>
                                {name || '-'}
                              </Table.Text>
                            </Table.Cell>
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'category',
                          ) && (
                              <Table.Cell id="category">
                                <Table.Text>
                                  {category?.name || '-'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some((h) => h.value === 'city') && (
                            <Table.CellToCountByKey id="city" entities={places} keyToCountBy="city" />
                          )}
                          {activeHeaders.some(
                            (h) => h.value === 'place_name',
                          ) && (
                              <Table.CellToCountByKey id="place_name" entities={places} keyToCountBy="name" />
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'is_enabled',
                          ) && (
                              <Table.Cell id="is_enabled">
                                <Table.Text>
                                  {is_enabled ? 'Активно' : 'Неактивно'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'provider',
                          ) && (
                              <Table.Cell id="provider">
                                <Table.Text>
                                  {provider ? provider : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'rating',
                          ) && (
                              <Table.Cell id="rating">
                                <Table.Text>
                                  {rating ? rating : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'preview_url',
                          ) && (
                              <Table.Cell id="preview_url">
                                <Table.Text>
                                  {preview_url?.length > 0 ? 'Есть' : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'description',
                          ) && (
                              <Table.Cell id="description">
                                <Table.Text>
                                  {description?.length > 0 ? 'Есть' : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'seances',
                          ) && (
                              <Table.Cell id="seances">
                                <Table.Text>
                                  {seances.length > 0 ? seances.length : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'label_top',
                          ) && (
                              <Table.Cell id="label_top">
                                <Table.Text>
                                  {label_top?.name ? label_top.name : '-'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'label_bottom',
                          ) && (
                              <Table.Cell id="label_bottom">
                                <Table.Text>
                                  {label_bottom?.name ? label_bottom.name : '-'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'source_owner',
                          ) && (
                              <Table.Cell id="source_owner">
                                <Table.Text>
                                  {source_owner ? source_owner : '-'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'data_enricher',
                          ) && (
                              <Table.Cell id="data_enricher">
                                {data_enricher?.length ? (
                                  <TextTooltip
                                    text={data_enricher.join(', ')}
                                    className="max-w-lg"
                                  >
                                    <Table.Text>
                                      {`${data_enricher.join(', ').substring(0, 30)}${data_enricher.join(', ').length > 30 ? '...' : ''}`}
                                    </Table.Text>
                                  </TextTooltip>
                                ) : (
                                  <Table.Text>
                                    {'-'}
                                  </Table.Text>
                                )}
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'changed_from_admin',
                          ) && (
                              <Table.Cell id="changed_from_admin">
                                <Table.Text>
                                  {changed_from_admin === true ? 'Да' : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                          {activeHeaders.some(
                            (h) => h.value === 'is_pushkin_card',
                          ) && (
                              <Table.Cell id="is_pushkin_card">
                                <Table.Text>
                                  {is_pushkin_card === true ? 'Да' : 'Нет'}
                                </Table.Text>
                              </Table.Cell>
                            )}
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={events}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Мероприятия не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Events;
