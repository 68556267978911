import React, { memo, useCallback } from 'react';

import useFetch from 'lib/hooks/useFetch';
import { PaginationApiResponse } from 'lib/api/api';
import { IFilters } from 'lib/hooks/useParams';
import { ChipsSelectFilter, IFetchParams } from '../ChipsSelectTemplate';
import { fetchData } from 'lib/api/fetchData';
import { ENDPOINTS } from 'lib/endpoints';

const PAGE_SIZE_CITIES = 24;

interface IChipsSelectCities {
    // eslint-disable-next-line
    idFromQuery: any;
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
    filterKey: string;
}

const ChipsSelectCities = <T extends { id: string; name: string }>({
    idFromQuery,
    setFilters,
    filterKey = "city_id",
}: IChipsSelectCities) => {
    const fetchCityById = useCallback((id: string) => fetchData<T>({
        method: 'get',
        path: ENDPOINTS.geopositionId(id),
    }), []);

    const {
        fetchData: fetchCities,
        data: citiesData,
        loading: citiesLoading,
        error: citiesError,
    } = useFetch<PaginationApiResponse<T[]>>();

    const fetchList = useCallback(({ page, searchQuery }: IFetchParams = {}) => {
        fetchCities({
            method: 'get',
            path: ENDPOINTS.geoposition(),
            params: {
                page: page ?? 0,
                page_size: PAGE_SIZE_CITIES,
                q: searchQuery,
            }
        });
    }, [fetchCities]);

    return (
        <ChipsSelectFilter
            top="Город"
            placeholder="Выберите город"
            filterKey={filterKey}
            idFromQuery={idFromQuery}
            setFilters={setFilters}
            fetchById={fetchCityById}
            data={citiesData}
            loading={citiesLoading}
            error={citiesError}
            fetchList={fetchList}
        />
    );
};

export default memo(ChipsSelectCities);
