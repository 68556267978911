import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Div,
  Group,
  Input,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import {
  Icon24Add,
  Icon24DeleteOutline,
  Icon24ListDeleteOutline,
} from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import { PaginationApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import TableSetup from 'components/common/TableSetup';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import { eventTagFilters, eventTagHeaders } from './constants.eventTagsDirectory';
import { IEventTag } from './types.eventTagsDirectory';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';

const EventTagsDirectory = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const [eventTags, setEventTags] = useState<IEventTag[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { checkedElements } = useCheckedElements<IEventTag>();
  const {
    filters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    currentPage,
    setCurrentPage,
  } = useParams(eventTagFilters);
  const { name, is_active } = filters;
  const { data, loading, fetchData } = useFetch<PaginationApiResponse<IEventTag[]>>();
  const fetchListFunction = () => {
    const queryParams = parseFiltersUrl(location.search) ?? ''
    fetchData({
      method: 'get',
      path: ENDPOINTS.tagTags(),
      params: {
        page: currentPage - 1,
        ...QueryString.parse(queryParams),
      }
    });
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setEventTags(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem(
      'columnOrder_directories_eventTags',
    );

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(eventTagHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            {checkedElements.length ? (
              <Button
                onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                className="vkui-edit-button-error min-w-fit"
                style={{ backgroundColor: '#E64646' }}
                size="m"
                before={<Icon24DeleteOutline />}
              >
                Удалить
              </Button>
            ) : (
              <Link to={ADMIN_DIRECTORIES_ROUTE + `/event_tags/addNew`}>
                <Button
                  mode="primary"
                  className="vkui-edit-button-primary min-w-fit"
                  size="m"
                  before={<Icon24Add />}
                >
                  Добавить
                </Button>
              </Link>
            )}
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="directories_eventTags"
              headers={eventTagHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters ? (
            <FiltersWrapper
              reset={reset}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Тег">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="name"
                  placeholder="Введите название тега"
                  value={name as string}
                  onChange={handleChange}
                />
              </FormItemPanel>
              <FormItemPanel top="Активность" className="p-0">
                <Select
                  className="vkui-select"
                  name="is_active"
                  placeholder="Выберите активность"
                  value={String(is_active)}
                  onChange={handleChange}
                  options={[
                    { label: 'Активно', value: 'true' },
                    { label: 'Неактивно', value: 'false' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          ) : null}
          {eventTags.length > 0 ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {eventTags.map((tag) => (
                      <tr
                        key={tag.id}
                        className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                        onClick={() =>
                          window.open(
                            ADMIN_DIRECTORIES_ROUTE +
                              `/event_tags/edit/${tag.id}`,
                            '_blank',
                          )
                        }
                      >
                        {activeHeaders.some((h) => h.value === 'name') && (
                          <td className="py-2" id="name">
                            <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                              {tag.name || '-'}
                            </Text>
                          </td>
                        )}
                        {activeHeaders.some((h) => h.value === 'is_active') && (
                          <td className="py-2" id="is_active">
                            <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                              {tag.is_active ? 'Активно' : 'Неактивно'}
                            </Text>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={eventTags}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Теги для мероприятий не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default EventTagsDirectory;
