import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import useFetch from 'lib/hooks/useFetch';
import { IChip } from 'lib/types';
import { PaginationApiResponse } from 'lib/api/api';
import { getUserRole } from 'lib/utils/authUtils';
import { debounce } from 'lib/utils/debounce';
import { ENDPOINTS } from 'lib/endpoints';
import { ChipsSelectWithLoaded } from 'components/common/ChipsSelectWithLoaded';
import { IGenre } from 'components/directories/GenresDirectory/types.genresDirectory';

const PAGE_SIZE_GENRES = 24;

type SelectGenresProps = {
	categoryId?: string | null;
	newGenres: IChip[];
	setNewGenres: React.Dispatch<React.SetStateAction<IChip[]>>;
};

const SelectGenres: FC<SelectGenresProps> = ({
	categoryId,
	newGenres,
	setNewGenres,
}) => {
	const userRole = getUserRole();
	const [allGenres, setAllGenres] = useState<IChip[]>([]);
	const [query, setQuery] = useState<string>();

	const {
		data: genresOptionsData,
		loading: genresOptionsLoading,
		fetchData: genresOptionsFetch,
		error: genresOptionsError,
	} = useFetch<PaginationApiResponse<IGenre[]>>();

	const fetchGenres = useCallback(async (params: { page?: number, query?: string } = {}) => {
		if (categoryId) {
			genresOptionsFetch({
				method: 'get',
				path: ENDPOINTS.genres(),
				params: {
					is_active: true,
					page: params?.page ?? genresOptionsData?.next,
					page_size: PAGE_SIZE_GENRES,
					category_id: categoryId,
					q: params?.query,
				},
			});
		}
	}, [categoryId, genresOptionsData?.next, genresOptionsFetch]);

	const onLoadMore = useCallback(() => {
		fetchGenres({ page: genresOptionsData?.next ?? 0, query });
	}, [fetchGenres, genresOptionsData?.next, query]);

	const fetchGenresByNewQuery = useCallback(debounce((query?: string) => {
		setAllGenres([]);
		fetchGenres({ page: 0, query });
	}), [fetchGenres]);

	useEffect(() => {
		if (!genresOptionsLoading && !genresOptionsError) {
			if (genresOptionsData?.results.length) {
				const newGenresOptions = genresOptionsData.results.map(
					({ id, name }) => ({
						label: name,
						value: id,
					}),
				);

				setAllGenres([...allGenres, ...newGenresOptions]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [genresOptionsLoading]);

	useEffect(() => {
		setAllGenres([]);
		setQuery('');
		if (categoryId) {
			fetchGenres({ page: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryId]);

	const changeQuery = (e?: React.ChangeEvent<HTMLInputElement>) => {
		const newQuery = e?.target.value;
		setQuery(newQuery);
		fetchGenresByNewQuery(newQuery)
	}

	return (
		<ChipsSelectWithLoaded
			name="genres"
			className="vkui-select"
			id="genres"
			placeholder="Укажите жанр"
			creatable={false}
			value={newGenres || []}
			options={allGenres}
			onChange={setNewGenres}
			disabled={userRole === 'support'}
			enableLoadMore={Boolean(genresOptionsData?.next)}
			errorLoadMore={Boolean(genresOptionsError)}
			onLoadMore={onLoadMore}
			onInputChange={changeQuery}
			inputValue={query}
			fetching={Boolean(!allGenres.length) && genresOptionsLoading}
			filterFn={false}
		/>
	);
};

export default memo(SelectGenres);
