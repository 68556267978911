import { useState, useCallback } from 'react';
import { useSnackbar } from './useSnackbar';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'lib/api/authService';
import { getUserToken } from 'lib/utils/authUtils';
import { ENDPOINTS } from 'lib/endpoints';
import { fetchData as fetchDataBasic, FetchDataParams, FetchDataResponseError } from 'lib/api/fetchData';

const useFetch = <T>() => {
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<FetchDataResponseError | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { setSnackbarContent } = useSnackbar();
	const { setUser } = useAuth();

	const handleRefreshToken = useCallback(async () => {
		const refreshToken = getUserToken('refresh');

		if (refreshToken) {
			try {
				const apiResponse = await authService.refreshToken(ENDPOINTS.authRefresh(), {
					refresh_token: refreshToken,
				});
				const userData = apiResponse.data;
				localStorage.setItem('user', JSON.stringify(userData));
				setUser(userData);
			} catch (error) {
				setSnackbarContent({
					type: 'error',
					message: 'Ошибка при авторизации',
				});
				localStorage.removeItem('user');
				setUser(null);
			}
		}
	}, [setSnackbarContent, setUser]);

	const fetchData = useCallback(async ({
		onSuccessMessage,
		onErrorMessage = 'Что-то пошло не так',
		...params
	}: FetchDataParams<T> & {
		onSuccessMessage?: string;
		onErrorMessage?: string;
	}) => {
		setLoading(true);

		const response = await fetchDataBasic(params, handleRefreshToken);

		if (response.isError) {
			setError(response.data);

			const getErrorMessage = () => {
				if (response.data.code !== 'ERR_NETWORK' && response.data.response?.data?.error_message !== 'Unique name violated') {
					return response.data.response?.data?.error_message ?? '';
				}
				return onErrorMessage
			}

			setSnackbarContent({
				type: 'error',
				message: getErrorMessage(),
			});

			setLoading(false);

			return {
				errorData: response.data,
			};

		} else {
			setData(response.data);
			setError(null);

			onSuccessMessage &&
				setSnackbarContent({
					type: 'success',
					message: onSuccessMessage,
				});

			setLoading(false);

			return null;
		}

	}, [handleRefreshToken, setSnackbarContent]);

	const resetData = useCallback(() => {
		setData(null);
		setError(null);
		setLoading(false);
	}, []);

	return { data, error, loading, fetchData, resetData };
};

export default useFetch;
