import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormItem, Input, Text } from '@vkontakte/vkui';
import { Icon20UserPenOutline } from '@vkontakte/icons';
import { FetchDataResponseError } from 'lib/api/fetchData';

interface EventFormItemProps {
  id?: string;
  top: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  isRequired: boolean;
  isError: boolean;
  errorItems: string[];
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: FetchDataResponseError | null;
  type?: string;
  maxLength?: number;
  // eslint-disable-next-line
  after?: any;
  autoComplete?: string | undefined;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  changed_from_admin?: string[];
}

const EventFormItem: React.FC<EventFormItemProps> = ({
  id,
  top,
  name,
  value,
  onChange,
  placeholder,
  isRequired,
  isError,
  errorItems,
  className,
  disabled,
  readOnly,
  error,
  type,
  maxLength,
  after,
  inputMode,
  autoComplete,
  changed_from_admin,
}) => {
  const [requestError, setRequestError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    setRequestError(false);
  };

  useEffect(() => {
    if (error) {
      setRequestError(true);
      setErrorMessage(
        error.parsedErrorFields?.find(
          // eslint-disable-next-line
          (errorField: any) => errorField.field === name,
        )?.message || '',
      );
    } else {
      setRequestError(false);
      setErrorMessage('');
    }
  }, [error]);

  return (
    <FormItem
      className={className}
      top={top}
      status={
        isRequired && isError && errorItems.includes(name) ? 'error' : 'default'
      }
      bottom={
        changed_from_admin?.includes(name) ? (
          <div className="flex items-center gap-1">
            <Icon20UserPenOutline fill="#99A2AD" width={18}/>
            <span style={{ color: '#99A2AD' }}>Изменено админом</span>
          </div>
        ) : (
          ''
        )
      }
    >
      <Input
        id={id}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        disabled={disabled}
        type={type}
        maxLength={maxLength}
        after={after}
        inputMode={inputMode}
        autoComplete={autoComplete}
      />
      {error && requestError && errorMessage && (
        <Text className="text-color-red">{errorMessage}</Text>
      )}
    </FormItem>
  );
};

export default EventFormItem;
