import React from 'react';
import { Spinner, SpinnerProps } from '@vkontakte/vkui';
import { useInView } from 'framer-motion';

type CustomSelectOptionWithInViewProps = {
	callback?: VoidFunction;
	once?: boolean;
} & SpinnerProps;

const SpinnerWithInView = ({
	callback,
	once = true,
	...props
}: CustomSelectOptionWithInViewProps) => {
	const ref = React.useRef(null);
	const isInView = useInView(ref, { once });

	React.useEffect(() => {
		if (isInView) {
			callback?.();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInView]);

	return (
		<div ref={ref}>
			<Spinner {...props} />
		</div>
	);
};

export default SpinnerWithInView;
