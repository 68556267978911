import React, { memo, useCallback } from 'react';

import useFetch from 'lib/hooks/useFetch';
import { PaginationApiResponse } from 'lib/api/api';
import { IFilters } from 'lib/hooks/useParams';
import { ChipsSelectFilter, IFetchParams } from '../ChipsSelectTemplate';
import { fetchData } from 'lib/api/fetchData';
import { ENDPOINTS } from 'lib/endpoints';

const PAGE_SIZE_CATEGORIES = 24;
const FILTER_KEY_CATEGORIES = "category_id";

interface IChipsSelectCategories {
    // eslint-disable-next-line
    idFromQuery: any;
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const ChipsSelectCategories = <T extends { id: string; name: string }>({
    idFromQuery,
    setFilters,
}: IChipsSelectCategories) => {
    const fetchCategoryById = useCallback((id: string) => fetchData<T>({
        method: 'get',
        path: ENDPOINTS.categoriesId(id),
    }), []);

    const {
        fetchData: fetchCategories,
        data: categoriesData,
        loading: categoriesLoading,
        error: categoriesError,
    } = useFetch<PaginationApiResponse<T[]>>();

    const fetchList = useCallback(({ page, searchQuery }: IFetchParams = {}) => {
        fetchCategories({
            method: 'get',
            path: ENDPOINTS.categories(),
            params: {
                page: page ?? 0,
                page_size: PAGE_SIZE_CATEGORIES,
                q: searchQuery,
                is_active: true,
            }
        });
    }, [fetchCategories]);

    return (
        <ChipsSelectFilter
            top="Тип"
            placeholder="Выберите тип"
            filterKey={FILTER_KEY_CATEGORIES}
            idFromQuery={idFromQuery}
            setFilters={setFilters}
            fetchById={fetchCategoryById}
            data={categoriesData}
            loading={categoriesLoading}
            error={categoriesError}
            fetchList={fetchList}
        />
    );
};

export default memo(ChipsSelectCategories);
