import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Div,
  FormItem,
  Group,
  Select,
  Spinner,
  Title,
} from '@vkontakte/vkui';
import { Icon24Hide, Icon24RefreshOutline, Icon24Copy } from '@vkontakte/icons';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { createPassword } from 'lib/utils/password.utils';
import { validateEmail } from 'lib/utils/inputVaildation';
import { NumberInput } from 'components/common/NumberInput';
import { userBlank, userFieldNameMap } from './constants.users';
import { IUserEdit } from './types.users';
import { ADMIN_USERS_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import EventFormItem from 'components/common/EventFormItem';
import PanelTitle from 'components/common/PanelTitle';
import getErrorMessage from 'lib/utils/getErrorMessage';
import { ENDPOINTS } from 'lib/endpoints';

const UsersEdit = () => {
  const { data, loading, fetchData, error } = useFetch<IUserEdit>();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const { fetchData: patchData } = useFetch();
  const { userId, methodType } = useParams();
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [userData, setUserData] = useState<IUserEdit>(userBlank);
  const { formData, handleChange, isError, errorItems } =
    useFormItems<IUserEdit>({
      initialValues: userData,
    });
  const { firstname, lastname, middlename, status, role } = formData;
  const [newPhone, setNewPhone] = useState<number | null>(null);
  const [newEmail, setNewEmail] = useState<string | null>(null);

  const handleSave = async () => {
    if (newEmail && !validateEmail(newEmail)) {
      setSnackbarContent({
        type: 'error',
        message: 'Необходимо ввести валидный email',
      });
    } else {
      if (methodType === 'edit') {
        const diffObject = findObjectsDiffs(formData, userData);
        const diffKeys = Object.keys(diffObject);
        // eslint-disable-next-line
        const diffData: Record<string, any> = {};
        if (diffKeys.length !== 0) {
          diffKeys.forEach((key) => {
            diffData[key] = formData[key as keyof IUserEdit];
          });
        }

        if (userData?.phone !== (newPhone !== null ? String(newPhone) : null)) {
          diffData.phone = newPhone === null ? null : String(newPhone);
        }

        if (userData?.email !== newEmail) diffData.email = newEmail;

        if (userData?.role !== role) diffData.status = status;

        if (Object.keys(diffData).length > 0) {
          const editPromise = patchData({
            method: 'patch',
            path: ENDPOINTS.businessProfileId(userId),
            body: replaceEmptyWithNull(diffData),
            onSuccessMessage: 'Изменения сохранены',
          });

          const response = await editPromise;

          const error = response?.errorData?.response?.data;

          if (error?.code) {
            setSnackbarContent({
              type: 'error',
              message: getErrorMessage(error, userFieldNameMap),
            });
          } else {
            await fetchDataFunction();
          }
        }
      }

      if (methodType === 'addNew') {
        // eslint-disable-next-line
        const { id, status, ...diffData } = formData;

        diffData.password = newPassword;
        if (newEmail) diffData.email = newEmail;
        if (newPhone) diffData.phone = String(newPhone);

        const addPromise = patchData({
          method: 'post',
          path: ENDPOINTS.businessProfile(),
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Успешно добавлено',
        });

        const response = await addPromise;

        const error =
          response?.errorData?.response?.data;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: getErrorMessage(error, userFieldNameMap),
          });
        } else {
          await navigate(ADMIN_USERS_ROUTE);
        }
      }
    }
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: ENDPOINTS.businessProfileId(userId), method: 'get' });
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };

  const generateNewPassword = () => {
    const newPassword = createPassword(12, 6);
    setNewPassword(newPassword);
  };

  useEffect(() => {
    if (data && !loading) {
      setUserData(data);
    }
  }, [loading]);

  useEffect(() => {
    setNewEmail(userData.email);
    setNewPhone(userData.phone ? Number(userData.phone) : null);
  }, [userData]);

  useEffect(() => {
    fetchDataFunction();
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">
              {methodType === 'edit' ? `Пользователь` : 'Добавить пользователя'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {userId}
              </Title>
            )}
          </PanelTitle>
          <Group className="custom-scrollbar">
            <EventFormItem
              className="vkui-input"
              top="Фамилия"
              name="lastname"
              value={lastname}
              onChange={handleChange}
              placeholder="Укажите фамилию"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
            />
            <EventFormItem
              className="vkui-input"
              top="Имя"
              name="firstname"
              value={firstname}
              onChange={handleChange}
              placeholder="Укажите имя"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
            />
            <EventFormItem
              className="vkui-input"
              top="Отчество"
              name="middlename"
              value={middlename}
              onChange={handleChange}
              placeholder="Укажите отчество (если имеется)"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
            />
            <EventFormItem
              id="new-uniuque-user-ml"
              className="vkui-input"
              top="Почта пользователя"
              name="newEmail"
              value={newEmail || ''}
              onChange={handleEmailChange}
              placeholder="Укажите почту"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
              error={error}
              autoComplete="off"
            />
            {methodType === 'addNew' && (
              <div className="flex items-center gap-4">
                <EventFormItem
                  id="new-uniuque-user-psswrd"
                  type={!isShowPassword ? 'password' : 'text'}
                  className="vkui-input w-96"
                  top="Пароль пользователя"
                  name="newPassword"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  placeholder="Укажите пароль"
                  isRequired={true}
                  isError={isError}
                  errorItems={errorItems}
                  error={error}
                  autoComplete="new-password"
                />
                <TextTooltip text="Показать/скрыть пароль" arrowPadding={1}>
                  <Icon24Hide
                    width={20}
                    color={'#5181B8'}
                    className="mt-6 cursor-pointer"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  />
                </TextTooltip>
                <TextTooltip text="Сгенерировать новый пароль" arrowPadding={1}>
                  <Icon24RefreshOutline
                    width={20}
                    color={'#5181B8'}
                    className="mt-6 cursor-pointer"
                    onClick={generateNewPassword}
                  />
                </TextTooltip>
                <TextTooltip text="Скопировать в буфер обмена" arrowPadding={1}>
                  <Icon24Copy
                    width={18}
                    color={'#5181B8'}
                    className="mt-6 cursor-pointer"
                    onClick={() => navigator.clipboard.writeText(newPassword)}
                  />
                </TextTooltip>
              </div>
            )}
            <FormItem className="vkui-input" top="Номер телефона">
              <NumberInput
                value={newPhone}
                onChange={(value: number | null) => setNewPhone(value)}
                name="phone"
                placeholder="Укажите номер телефона"
                maxLength={15}
              />
            </FormItem>
            {methodType === 'edit' && (
              <FormItem top="Статус">
                <Select
                  name="status"
                  className="vkui-select"
                  placeholder="Укажите статус"
                  value={status}
                  onChange={handleChange}
                  options={[
                    { value: 'active', label: 'Активен' },
                    { value: 'blocked', label: 'Заблокирован' },
                  ]}
                />
              </FormItem>
            )}
            <FormItem top="Роль">
              <Select
                name="role"
                className="vkui-select"
                placeholder="Укажите роль"
                value={role}
                onChange={handleChange}
                options={[
                  { value: 'admin', label: 'Администратор' },
                  { value: 'support', label: 'Поддержка' },
                  { value: 'editor', label: 'Редактор' },
                ]}
              />
            </FormItem>
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <Button
                    className="vkui-edit-button-primary"
                    mode="primary"
                    size="m"
                    appearance="accent"
                    onClick={handleSave}
                  >
                    Сохранить
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => navigate(ADMIN_USERS_ROUTE)}
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
    </>
  );
};

export default UsersEdit;
