export type PersonEntityType = `event` | `place` | `person`;

export const ENDPOINTS = {
	//events
	events: () => `/v2/events`,
	eventsId: (id?: string) =>`/v2/events/${id}`,
	eventsLinks: (id?: string) =>`/v2/events/${id}/links`,
	eventsResetChangedFields: (id?: string) =>`/v2/events/${id}/reset-changed-fields`,
	//places
	places: () =>`/v2/places`,
	placesId: (id?: string) =>`/v2/places/${id}`,
	placesResetChangedFields: (id?: string) =>`/v2/places/${id}/reset-changed-fields`,
	//persons
	persons: () => `/v1/person/persons/`,
	personsId: (id?: string) => `/v1/person/persons/${id}/`,
	personsEntities: (personId: string) => `/v1/person/persons/${personId}/link/`,
	//geoposition
	geoposition: () =>`/v1/geoposition/`,
	geopositionId: (id: string) =>`/v1/geoposition/${id}`,
	//labels
	label: () =>`/v2/labels`,
	labelId: (id?: string) =>`/v2/labels/${id}`,
	//categories
	categories: () =>`/v2/categories`,
	categoriesId: (id?: string) =>`/v2/categories/${id}`,
	//genres
	genres: () =>`/v2/genres`,
	genresId: (id?: string) =>`/v2/genres/${id}`,
	//seances
	seances: () =>`/v2/seances`,
	//tag
	tagTags: () =>`/v1/tag/tags/`,
	tagTagsId: (id?: string) =>`/v1/tag/tags/${id}/`,
	//order
	orders: () =>`/v1/order/orders/`,
	ordersId: (id?: string) =>`/v1/order/orders/${id}/`,
	ordersResend: () =>`/v1/order/orders/resend/`,
	ordersTickets: () =>`/v1/order/orders/tickets/`,
	//ranking
	rankings: () =>`/v1/ranking/rankings/`,
	rankingsId: (id?: string) =>`/v1/ranking/rankings/${id}/`,
	//rate
	rateRatings: () =>`/v1/rate/ratings/`,
	rateRatingsId: (id?: string) =>`/v1/rate/ratings/${id}/`,
	//business-profile
	businessProfile: () =>`/v1/business-profile/`,
	businessProfileMe: () =>`/v1/business-profile/me/`,
	businessProfileId: (id?: string) =>`/v1/business-profile/${id}`,
	//login
	authLogin: () =>`/v1/auth/login`,
	authRefresh: () =>`/v1/auth/refresh`,
};
