import { useEffect, useRef, useState } from "react";
import useInfiniteScrollHook from "react-infinite-scroll-hook"
import useDebounce from "./useDebounce";

export const useInfiniteScroll = <T>({
  hasNextPage, 
  isLoading, 
  fetcher, 
  data: data, 
  searchInputDelay = 500, 
  loadingDelay = 200,
  loadingMargin = "0px 0px 0px 0px"
}: {
  hasNextPage: boolean;
  isLoading: boolean;
  fetcher: (currentPage: number, search: string) => void;
  data: T[];
  searchInputDelay?: number;
  loadingDelay?: number
  loadingMargin?: string
}) => {
  const [allValues, setAllValues] = useState<T[]>([]);
  const [currentValues, setCurrentValues] = useState<T[]>([]);
  const [valuesCurrentPage, setValuesCurrentPage] = useState<number>(0);
  const [valuesSearch, setValuesSearch] = useState<string>('');
  const debouncedValueSearch = useDebounce(valuesSearch, searchInputDelay);
  const valueListInnerRef = useRef<HTMLDivElement>(null);

  const [valuesSentryRef, { rootRef: valuesRootRef }] = useInfiniteScrollHook({
    onLoadMore: () => {
      setValuesCurrentPage((prev) => prev + 1);
    },
    hasNextPage: hasNextPage,
    loading: isLoading,
    rootMargin: loadingMargin,
    delayInMs: loadingDelay,
  });

  useEffect(() => {
    fetcher(valuesCurrentPage, valuesSearch)
  }, [valuesCurrentPage, debouncedValueSearch]);

  useEffect(() => {
    setValuesCurrentPage(0);
    setAllValues([]);
  }, [valuesSearch]);

  useEffect(() => {
    if (!isLoading && data) {
      setCurrentValues(data);
      setAllValues((prev) => [...prev, ...data]);
    }
  }, [isLoading]);

  return {
    setAllValues,
    setCurrentValues,
    setValuesCurrentPage,
    setValuesSearch,
    allValues,
    currentValues,
    valuesCurrentPage,
    valuesSearch,
    debouncedValueSearch,
    valueListInnerRef,
    valuesSentryRef,
    valuesRootRef
  }
}