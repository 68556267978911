import React, { useEffect, useState } from 'react';
import { Div, Placeholder, Spinner } from '@vkontakte/vkui';
import useFetch from 'lib/hooks/useFetch';
import { useParams } from 'react-router-dom';
import useParamsHook from 'lib/hooks/useParams';
import {
  Icon24TicketOutline,
  Icon16Replay,
  Icon16PaymentCardOutline,
} from '@vkontakte/icons';
import { PaginationApiResponse } from 'lib/api/api';
import PaginationComponent from 'components/common/Pagination';
import { IOrderTicket } from './types.orders';
import { orderTicketsFilters, orderTicketsHeaders } from './constants.orders';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { formatPrice } from 'lib/utils/formatPrice';
import { parseDateFrom } from 'lib/utils/date.utils';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';

const OrderTickets = () => {
  const { orderId } = useParams();
  const [tickets, setTickets] = useState<IOrderTicket[]>([]);
  const { currentPage, setCurrentPage } = useParamsHook(orderTicketsFilters);
  const {
    data: ticketsData,
    loading: ticketsLoading,
    fetchData: fetchTickets,
  } = useFetch<PaginationApiResponse<IOrderTicket[]>>();

  const fetchTicketsFunction = () => {
    const queryParams = parseFiltersUrl(location.search) ?? ''

    fetchTickets({
      method: 'get',
      path: ENDPOINTS.ordersTickets(),
      params: {
        page: currentPage - 1,
        order_id: orderId,
        ...QueryString.parse(queryParams),
      }
    });
  };

  useEffect(() => {
    fetchTicketsFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (ticketsData?.results && !ticketsLoading) {
      setTickets(ticketsData.results);
    }
  }, [ticketsLoading]);

  return (
    <>
      {ticketsLoading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <Div>
            {tickets.length > 0 ? (
              <>
                <table className="w-full table-fixed">
                  <thead className="bg-color-steel-gray-20 h-10 text-color-steel-gray-500 text-sm text-left">
                    <tr>
                      {orderTicketsHeaders.map((head) => {
                        return (
                          <th className="pl-4 uppercase font-medium" key={head}>
                            {head}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map(
                      ({
                        id,
                        number,
                        price,
                        fee,
                        sector,
                        row,
                        seat,
                        status,
                        refunded_date,
                      }) => {
                        return (
                          <tr key={id} className="text-sm">
                            <td className="p-4">{number}</td>
                            <td className="p-4" id="status">
                              {status ? (
                                <>
                                  {status === 'refunded' && (
                                    <div className="bg-orange-100 text-orange-500 flex justify-between gap-2 items-center p-1 rounded">
                                      {'Возвращен'}
                                      <Icon16Replay color={'rgb(251 146 60)'} />
                                    </div>
                                  )}
                                  {status === 'paid' && (
                                    <div className="bg-green-100 text-green-600 flex justify-between gap-2 items-center p-1 rounded">
                                      {'Оплачен'}
                                      <Icon16PaymentCardOutline
                                        color={'rgb(34 197 94)'}
                                      />
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>{'-'}</>
                              )}
                            </td>
                            <td className="p-4">
                              {price ? formatPrice(price) : '-'}
                            </td>
                            <td className="p-4">
                              {fee ? formatPrice(fee) : '-'}
                            </td>
                            <td className="p-4">{sector ? sector : '-'}</td>
                            <td className="p-4">{row ? row : '-'}</td>
                            <td className="p-4">{seat ? seat : '-'}</td>
                            <td className="p-4">
                              {refunded_date !== null &&
                              typeof refunded_date === 'string'
                                ? parseDateFrom({
                                    date: refunded_date,
                                    format: 'daytime',
                                    utc: false,
                                  })
                                : '-'}
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
                {ticketsData && ticketsData?.count > 10 && (
                  <PaginationComponent
                    count={ticketsData?.count}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    items={tickets}
                  />
                )}
              </>
            ) : (
              <Div>
                <Placeholder
                  icon={<Icon24TicketOutline />}
                  header={'Нет билетов'}
                >
                  В данном заказе билеты не найдены
                </Placeholder>
              </Div>
            )}
          </Div>
        </>
      )}
    </>
  );
};

export default OrderTickets;
