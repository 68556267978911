import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Div,
  Group,
  Placeholder,
  Select,
  Spinner,
  Text,
  Input,
  Title,
  Checkbox,
} from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon24ListDeleteOutline } from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import {
  ADMIN_PLACES_ROUTE,
  dataEnricherOptions,
  dataOwnerOptions,
} from 'lib/constants';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { PaginationApiResponse } from 'lib/api/api';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { IPlace } from './types.places';
import {
  placeFilters,
  placeHeaders,
  placeTypeOptions,
} from './constants.places';
import TableSetup from 'components/common/TableSetup';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FormItemPanel from 'components/common/FormItemPanel';
import FiltersWrapper from 'components/common/FiltersWrapper';
import PaginationComponent from 'components/common/Pagination';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import clsx from 'clsx';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';
import { ChipsSelectCities } from 'components/common/ChipsSelectCities';

type Props = {
  action?: React.ReactNode;
  pageSize?: number;
  absolutePositionedFilters?: boolean;
  selectedPlaces?: IPlace[];
  setSelectedPlaces?: React.Dispatch<React.SetStateAction<IPlace[]>>;
};

const Places: FC<Props> = ({
  action,
  pageSize = 10,
  absolutePositionedFilters,
  selectedPlaces,
  setSelectedPlaces,
}) => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<PaginationApiResponse<IPlace[]>>();
  const location = useLocation();
  const [places, setPlaces] = useState<IPlace[]>([]);
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    setCurrentPage,
    currentPage,
  } = useParams(placeFilters);
  const {
    place_type_ru,
    city_id,
    status,
    description_exists,
    picture_exists,
    source_owner,
    data_enricher,
    changed_from_admin,
    vk_id,
  } = filters;

  const selectedPlacesIds = React.useMemo(
    () => selectedPlaces?.map(({ id }) => id),
    [selectedPlaces],
  );

  const handleCheckboxChange = React.useCallback(
    (place: IPlace) => {
      if (selectedPlacesIds?.includes(place.id)) {
        setSelectedPlaces?.((prev) => prev.filter(({ id }) => id !== place.id));
      } else {
        setSelectedPlaces?.((prev) => [...prev, place]);
      }
    },
    [selectedPlacesIds, setSelectedPlaces],
  );

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters({ ...filters, q: value });
  };

  const handleResetFilters = useCallback(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const queryParams = parseFiltersUrl(location.search) ?? ''

    fetchData({
      method: 'get',
      path: ENDPOINTS.places(),
      params: {
        page_size: pageSize,
        page: currentPage - 1,
        ...QueryString.parse(queryParams),
      }
    });
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setPlaces(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_places');
    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(placeHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={filters.q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="places"
              headers={placeHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
            {action}
          </Div>
          {openFilters && (
            <div className={clsx(absolutePositionedFilters && 'relative')}>
              <FiltersWrapper
                reset={handleResetFilters}
                addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
                className={clsx(
                  absolutePositionedFilters &&
                  'absolute left-0 top-0 z-10 w-full bg-white shadow-sm',
                )}
              >
                <FormItemPanel top="Тип площадки" className="p-0">
                  <Select
                    allowClearButton
                    className="vkui-select"
                    id="place_type_ru"
                    name="place_type_ru"
                    placeholder="Выберите тип площадки"
                    value={place_type_ru as string}
                    onChange={handleChange}
                    options={placeTypeOptions}
                  />
                </FormItemPanel>
                <ChipsSelectCities 
                  filterKey="city_id"
                  idFromQuery={city_id}
                  setFilters={setFilters}
                />
                <FormItemPanel top="Статус" htmlFor="status">
                  <Select
                    className="vkui-select"
                    id="status"
                    name="status"
                    placeholder="Выберите статус"
                    value={status as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Опубликован' },
                      { value: 'false', label: 'Скрыт' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Описание" htmlFor="description_exists">
                  <Select
                    className="vkui-select"
                    id="description_exists"
                    name="description_exists"
                    placeholder="Наличие описания"
                    value={description_exists as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Есть' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Изображение" htmlFor="images">
                  <Select
                    className="vkui-select"
                    id="picture_exists"
                    name="picture_exists"
                    placeholder="Наличие изображения"
                    value={picture_exists as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Есть' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="Владелец данных" htmlFor="source_owner">
                  <Select
                    className="vkui-select"
                    id="source_owner"
                    name="source_owner"
                    placeholder="Выберите владельца"
                    value={source_owner as string}
                    onChange={handleChange}
                    allowClearButton
                    options={dataOwnerOptions}
                  />
                </FormItemPanel>
                <FormItemPanel top="Обогащение данных" htmlFor="data_enricher">
                  <Select
                    className="vkui-select"
                    id="data_enricher"
                    name="data_enricher"
                    placeholder="Выберите источник"
                    value={data_enricher as string}
                    onChange={handleChange}
                    allowClearButton
                    options={dataEnricherOptions}
                  />
                </FormItemPanel>
                <FormItemPanel
                  top="Изменено админом"
                  htmlFor="changed_from_admin"
                >
                  <Select
                    className="vkui-select"
                    id="changed_from_admin"
                    name="changed_from_admin"
                    placeholder="Изменено админом"
                    value={changed_from_admin as string}
                    onChange={handleChange}
                    allowClearButton
                    options={[
                      { value: 'true', label: 'Да' },
                      { value: 'false', label: 'Нет' },
                    ]}
                  />
                </FormItemPanel>
                <FormItemPanel top="ID виджета сообщества">
                  <Input
                    onKeyDown={(event) => {
                      handleKeyDown(event, addFiltersAndUpdateUrl);
                    }}
                    className="vkui-input"
                    name="vk_id"
                    placeholder="Укажите ID"
                    value={vk_id as string}
                    onChange={handleChange}
                    before={<Icon16SearchOutline fill="#99A2AD" />}
                  />
                </FormItemPanel>
              </FiltersWrapper>
            </div>
          )}
          {places && places.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {setSelectedPlaces && <th></th>}
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="whitespace-nowrap py-4 uppercase text-color-gray-600"
                        >
                          <Title
                            className="ml-8 flex max-w-fit cursor-pointer items-center gap-1 text-sm"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {places &&
                      places.map((place) => {
                        const {
                          id,
                          name,
                          city,
                          status,
                          place_type_ru,
                          description_exists,
                          picture,
                          source_owner,
                          data_enricher,
                          changed_from_admin,
                        } = place;

                        return (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_PLACES_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="h-10 cursor-pointer transition-colors hover:bg-color-steel-gray-20"
                          >
                            {setSelectedPlaces && (
                              <td
                                className="py-1"
                                onClick={(event) => event.stopPropagation()}
                              >
                                <Checkbox
                                  checked={selectedPlacesIds?.includes(
                                    place.id,
                                  )}
                                  onClick={() => handleCheckboxChange(place)}
                                />
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'id') && (
                              <td className="py-2" id="id">
                                <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                  {id.split('-').pop() || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'name') && (
                              <td className="py-2" id="name">
                                <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                  {name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'place_type_ru',
                            ) && (
                                <td className="py-2" id="place_type_ru">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {place_type_ru || '-'}
                                  </Text>
                                </td>
                              )}
                            {activeHeaders.some((h) => h.value === 'city') && (
                              <td className="py-2" id="city">
                                <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                  {city || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'status',
                            ) && (
                                <td className="py-2" id="status">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {status !== null
                                      ? status
                                        ? 'Опубликован'
                                        : 'Скрыт'
                                      : '-'}
                                  </Text>
                                </td>
                              )}
                            {activeHeaders.some(
                              (h) => h.value === 'description_exists',
                            ) && (
                                <td className="py-2" id="description_exists">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {description_exists ? 'Есть' : 'Нет'}
                                  </Text>
                                </td>
                              )}
                            {activeHeaders.some(
                              (h) => h.value === 'picture_exists',
                            ) && (
                                <td className="py-2" id="picture_exists">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {picture ? 'Есть' : 'Нет'}
                                  </Text>
                                </td>
                              )}
                            {activeHeaders.some(
                              (h) => h.value === 'source_owner',
                            ) && (
                                <td className="py-2" id="source_owner">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {source_owner ? source_owner : '-'}
                                  </Text>
                                </td>
                              )}
                            {activeHeaders.some(
                              (h) => h.value === 'data_enricher',
                            ) && (
                                <td className="py-2" id="data_enricher">
                                  {data_enricher?.length ? (
                                    <TextTooltip
                                      text={data_enricher.join(', ')}
                                      className="max-w-lg"
                                    >
                                      <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                        {`${data_enricher.join(', ').substring(0, 30)}${data_enricher.join(', ').length > 30 ? '...' : ''}`}
                                      </Text>
                                    </TextTooltip>
                                  ) : (
                                    <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                      {'-'}
                                    </Text>
                                  )}
                                </td>
                              )}
                            {activeHeaders.some(
                              (h) => h.value === 'changed_from_admin',
                            ) && (
                                <td className="py-2" id="changed_from_admin">
                                  <Text className="ml-8 min-w-156 max-w-19 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {changed_from_admin === true ? 'Да' : 'Нет'}
                                  </Text>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={places}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Места проведения не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Places;
