import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  Group,
  ModalCard,
  ModalRoot,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import {
  Icon24Add,
  Icon24DeleteOutline,
  Icon24ListDeleteOutline,
} from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import { useCheckedElements } from 'providers/CheckedElementsContext';
import { PaginationApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import {
  eventLabelFilters,
  eventLabelHeaders,
} from './constants.eventLabelsDirectory';
import { IEventLabel } from './types.eventLabelsDirectory';
import InputSearch from 'components/common/InputSearch';
import FiltersButton from 'components/common/FiltersButton';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import TableSetup from 'components/common/TableSetup';
import { ENDPOINTS } from 'lib/endpoints';
import QueryString from 'qs';

const EventLabelsDirectory = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<PaginationApiResponse<IEventLabel[]>>();
  const { loading: deleteLoading, fetchData: deleteFetch } = useFetch();
  const [openModal, setOpenModal] = useState<string>('');
  const [eventLabels, setEventLabels] = useState<IEventLabel[]>([]);
  const {
    filters,
    setFilters,
    openFilters,
    handletoggleFilters,
    addFiltersAndUpdateUrl,
    handleChange,
    reset,
    currentPage,
    setCurrentPage,
  } = useParams(eventLabelFilters);
  const { q, type, is_active } = filters;
  const {
    checkedElements,
    handleCheckedItem,
    handleToggleAllCheckValue,
    clearCheckedElements,
  } = useCheckedElements<IEventLabel>();

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilters({ ...filters, q: value });
  };

  const handleDeleteEventLabels = async () => {
    await deleteFetch({
      method: 'delete',
      path: ENDPOINTS.label(),
      params: {
        label_id: checkedElements,
      },
      onSuccessMessage: 'Успешно удалено',
      body: checkedElements,
    });

    await setOpenModal('');
    await fetchListFunction();
    await clearCheckedElements();
  };

  const fetchListFunction = () => {
    const queryParams = parseFiltersUrl(location.search) ?? ''

    fetchData({
      method: 'get',
      path: ENDPOINTS.label(),
      params: {
        page: currentPage - 1,
        ...QueryString.parse(queryParams),
      }
    });
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setEventLabels(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem(
      'columnOrder_directories_eventLabels',
    );

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(eventLabelHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            {checkedElements.length ? (
              <Button
                onClick={() => setOpenModal('deleteModal')}
                className="vkui-edit-button-error min-w-fit"
                style={{ backgroundColor: '#E64646' }}
                size="m"
                before={<Icon24DeleteOutline />}
              >
                Удалить
              </Button>
            ) : (
              <Link to={ADMIN_DIRECTORIES_ROUTE + `/event_labels/addNew`}>
                <Button
                  mode="primary"
                  className="vkui-edit-button-primary min-w-fit"
                  size="m"
                  before={<Icon24Add />}
                >
                  Добавить
                </Button>
              </Link>
            )}
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="directories_eventLabels"
              headers={eventLabelHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={reset}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Тип">
                <Select
                  className="vkui-select"
                  name="type"
                  placeholder="Выберите тип"
                  value={type as string}
                  onChange={handleChange}
                  options={[
                    { label: 'Свойство мероприятия', value: 'event' },
                    { label: 'Свойство цены', value: 'price' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Активность">
                <Select
                  className="vkui-select"
                  name="is_active"
                  placeholder="Выберите активность"
                  value={String(is_active)}
                  onChange={handleChange}
                  options={[
                    { label: 'Активно', value: 'true' },
                    { label: 'Неактивно', value: 'false' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          )}
          {eventLabels?.length > 0 ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      <th>
                        <Checkbox
                          checked={
                            checkedElements.length === eventLabels.length
                          }
                          onChange={() =>
                            handleToggleAllCheckValue(eventLabels)
                          }
                        />
                      </th>
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                          >
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {eventLabels.map((label) => (
                      <tr
                        key={label.id}
                        className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                        onClick={() =>
                          window.open(
                            ADMIN_DIRECTORIES_ROUTE +
                            `/event_labels/edit/${label.id}`,
                            '_blank',
                          )
                        }
                      >
                        {checkedElements && (
                          <td
                            className="py-2"
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Checkbox
                              checked={checkedElements.includes(label.id)}
                              onChange={() => handleCheckedItem(label.id)}
                            />
                          </td>
                        )}

                        {activeHeaders.some((h) => h.value === 'name') && (
                          <td className="py-2" id="name">
                            <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                              {label?.name || '-'}
                            </Text>
                          </td>
                        )}

                        {activeHeaders.some((h) => h.value === 'type') && (
                          <td className="py-2" id="type">
                            <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                              {label.type
                                ? label.type === 'event'
                                  ? 'Свойство мероприятия'
                                  : 'Свойство цены'
                                : '-'}
                            </Text>
                          </td>
                        )}

                        {activeHeaders.some((h) => h.value === 'is_active') && (
                          <td className="py-2" id="is_active">
                            <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                              {label.is_active !== null
                                ? label.is_active === true
                                  ? 'Активно'
                                  : 'Неактивно'
                                : '-'}
                            </Text>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={eventLabels}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Лейблы мероприятий не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard
          id="deleteModal"
          onClose={() => setOpenModal('')}
          className="fixed"
        >
          {deleteLoading ? (
            <Div className="p-0" style={{ width: '400px', height: '156px' }}>
              <Spinner size="medium" className="spinner" />
            </Div>
          ) : (
            <Div className="p-0 flex flex-col gap-2">
              <Title className="text-color-black" level="2">
                Удаление лейблов
              </Title>
              <Text className="text-color-gray-600">
                Выбранные лейблы будут удалены
              </Text>
              <ButtonGroup className="pt-3" align="right" stretched>
                <Button
                  className="vkui-edit-button-secondary"
                  mode="secondary"
                  size="m"
                  onClick={() => setOpenModal('')}
                >
                  Отмена
                </Button>
                <Button
                  className="vkui-edit-button-primary"
                  mode="primary"
                  size="m"
                  onClick={() => handleDeleteEventLabels()}
                >
                  Удалить
                </Button>
              </ButtonGroup>
            </Div>
          )}
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default EventLabelsDirectory;
