import qs from 'qs';

type WithQueryStringParams = {
    path: string,
    params?: Record<string, unknown>,
    hash?: string | null,
    options?: qs.IStringifyOptions
}

export const withQueryString = ({
    path,
    params = {},
    hash,
    options = { encode: false },
}: WithQueryStringParams): string => {
    const search = qs.stringify(params, options);
    const searchPart = search ? `?${search}` : '';
    const hashPart = hash ? `#${hash}` : '';

    return `${path}${searchPart}${hashPart}`;
};
