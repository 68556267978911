import React, { memo } from 'react';
import {
  CellButton,
  Checkbox,
} from '@vkontakte/vkui';
import { IChip } from 'lib/types';

interface IChipCheckbox {
  checked: boolean;
  onChange: (item: IChip) => void;
  chip: IChip
};

const ChipCheckbox = ({ onChange, checked, chip }: IChipCheckbox) => {
  return (
    <CellButton
      key={chip.value}
      className={!checked ? "text-color-black" : ''}
      before={
        <Checkbox
          className="pointer-events-none"
          checked={checked}
        />
      }
      onClick={() => onChange(chip)}
    >
      {chip.label}
    </CellButton>
  );
};

export default memo(ChipCheckbox);
