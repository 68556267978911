import { AxiosResponse, AxiosError } from "axios";
import { IResponseError, IResponseFieldError } from "lib/types";
import { withQueryString } from "lib/utils/withQueryString";
import apiService from "./api";

export type FetchDataParams<T> = {
    path: string;
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
    body?: T;
    params?: Record<string, unknown>;
}

export type FetchDataResponseError = AxiosError<IResponseError, any> & {
    parsedErrorFields?: {
        field?: any;
        message?: string;
    }[];
}

export type FetchDataResponse<T> = { isError: false; data: T } | { isError: true; data: FetchDataResponseError };

export const fetchData = async<T>({
    path,
    method = 'get',
    body,
    params,
}: FetchDataParams<T>, handleRefreshToken?: () => Promise<void>): Promise<FetchDataResponse<T>> => {
    try {
        const pathWithParams = withQueryString({ path, params });

        let response: AxiosResponse<T>;

        switch (method) {
            case 'post':
                response = await apiService.post<T>(pathWithParams, body);
                break;
            case 'put':
                response = await apiService.put<T>(pathWithParams, body);
                break;
            case 'patch':
                response = await apiService.patch<T>(pathWithParams, body);
                break;
            case 'delete':
                body
                    ? (response = await apiService.deleteAll<T>(pathWithParams, body))
                    : (response = await apiService.delete<T>(pathWithParams));
                break;
            default:
                response = await apiService.get<T>(pathWithParams);
        }

        return {
            isError: false,
            data: response.data,
        }
    } catch (error) {
        const typedError = error as AxiosError<IResponseError>;

        if (
            handleRefreshToken && (
                typedError.response?.data.error_message === 'Signature verification failed' ||
                typedError.response?.data.error_message === 'Signature has expired'
            )
        ) {
            handleRefreshToken();
        }

        if (typedError.code === 'ERR_NETWORK') {
            return {
                isError: true,
                data: typedError,
            }
        }

        const errorResponse = typedError.response?.data;

        const parsedErrorFields =
            errorResponse?.field_errors?.map((item: IResponseFieldError) => {
                const match = item.message?.match(/<([^>]*)>/);
                const fieldName = match ? match[1] : '';

                return {
                    field: item.field,
                    message: item.message
                        ? item.message.includes('already exists')
                            ? `${fieldName} уже существует`
                            : item.message
                        : 'Неизвестная ошибка',
                };
            }) || [];

        return {
            isError: true,
            data: {
                ...typedError,
                parsedErrorFields,
            },
        }
    }
};