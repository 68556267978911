import React, { memo, useEffect, useState } from 'react';

import { IChip } from 'lib/types';
import { PaginationApiResponse } from 'lib/api/api';
import FormItemPanel from '../FormItemPanel';
import { IFilters } from 'lib/hooks/useParams';
import { useGetChipById } from './useGetChipById';
import { ChipsSelect } from './ChipsSelect';
import { FetchDataResponse, FetchDataResponseError } from 'lib/api/fetchData';
import { IFetchParams } from './ChipsSelect/ChipsSelect';

interface IChipsSelectFilter<T> {
  // eslint-disable-next-line
  idFromQuery: any;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  filterKey: string;
  top: string;
  placeholder: string;
  fetchById: (id: string) => Promise<FetchDataResponse<T>>;
  data: PaginationApiResponse<T[]> | null;
  loading: boolean;
  error: FetchDataResponseError | null;
  fetchList: (params?: IFetchParams) => void;
  transformDataToChip?: (data: T) => IChip;
}

const ChipsSelectFilter = <T extends { id: string; name: string }>({
  idFromQuery,
  setFilters,
  filterKey,
  top,
  placeholder,
  fetchById,
  data,
  loading,
  error,
  fetchList,
  transformDataToChip = (data) => ({ label: data.name, value: data.id }),
}: IChipsSelectFilter<T>) => {
  const [selectedChip, setSelectedChips] = useState<IChip[]>([]);
  const [allChips, setAllChips] = useState<IChip[]>([]);

  //Устанавливаем в выбранные данные из query параметров
  useGetChipById({ idFromQuery, setSelectedChips, fetchById });

  //Добавляем загруженные данные к текущему списку 
  useEffect(() => {
    if (!loading && !error && data?.results) {
      const newChips: IChip[] = data.results.map(transformDataToChip)
      setAllChips((prev) => [...prev, ...newChips]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);


  useEffect(() => {
    setFilters((prev) => ({ ...prev, [filterKey]: selectedChip }));
  }, [filterKey, selectedChip, setFilters]);

  return (
    <FormItemPanel top={top}>
      <ChipsSelect
        placeholder={placeholder}
        options={allChips}
        setOption={setAllChips}
        values={selectedChip}
        setValues={setSelectedChips}
        fetch={fetchList}
        nextPage={data?.next ?? 0}
        error={Boolean(error)}
        loading={loading}
      />
    </FormItemPanel>
  );
};

export default memo(ChipsSelectFilter);
