import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  FormLayout,
  Group,
  FormItem,
  Input,
  Button,
  Title,
  Div,
} from '@vkontakte/vkui';
import { Icon56LogoVkColor } from '@vkontakte/icons';
import { useAuth } from 'providers/AuthProvider';
import { IAuth } from 'lib/types';
import { authService } from 'lib/api/authService';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import Layout from 'layout';
import { ADMIN_ORDERS_ROUTE } from 'lib/constants';
import { ENDPOINTS } from 'lib/endpoints';

const AuthPage: FC = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const [formData, setFormData] = useState<IAuth>({
    email: '',
    password: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    try {
      const apiResponse = await authService.login(ENDPOINTS.authLogin(), formData);
      const userData = apiResponse.data;
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при авторизации',
      });
    }
  };

  useEffect(() => {
    const storedUser: string | null = localStorage.getItem('user');
    if (user && storedUser && user.role) {
      navigate(ADMIN_ORDERS_ROUTE);
    }
  }, [user, navigate]);

  return (
    <Layout>
      <Group className="max-w-xs absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <FormLayout>
          <div className="flex gap-2 items-center justify-center pb-1 pt-6">
            <Icon56LogoVkColor />
            <Title level="1">Гид</Title>
          </div>
          <FormItem htmlFor="email" top="E-mail">
            <Input
              onKeyDown={(event) => {
                handleKeyDown(event, handleLogin);
              }}
              onChange={handleChange}
              placeholder="Введите e-mail"
              id="email"
              type="email"
              name="email"
              value={formData.email}
            />
          </FormItem>
          <FormItem top="Пароль" htmlFor="pass">
            <Input
              onKeyDown={(event) => {
                handleKeyDown(event, handleLogin);
              }}
              onChange={handleChange}
              id="pass"
              type="password"
              placeholder="Введите пароль"
              name="password"
              value={formData.password}
            />
          </FormItem>
          <Div className="mt-4">
            <Button
              className="vkui-edit-button-primary"
              mode="primary"
              size="s"
              appearance="accent"
              stretched
              onClick={handleLogin}
            >
              Войти
            </Button>
          </Div>
        </FormLayout>
      </Group>
    </Layout>
  );
};

export default AuthPage;
