import React, { memo } from 'react';
import {
  Chip,
  Div,
  Text,
} from '@vkontakte/vkui';
import { Icon20Dropdown } from '@vkontakte/icons';
import { IChip } from 'lib/types';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';

interface IPlaceholder {
  placeholder?: string;
  values: IChip[];
  onChangeOption: (options: IChip) => void;
  shown: boolean;
};

const Placeholder = ({ placeholder, values, onChangeOption, shown }: IPlaceholder) => {

  if (values.length) {
    return (
      <>
        <Div className="p-0 flex gap-1 -ml-1">
          {values[0] && (
            <Chip
              onRemove={() => onChangeOption(values[0])}
              value={values[0].value}
              className="max-w-[175px] truncate"
            >
              {values[0].label}
            </Chip>
          )}
          {values[1] && (
            <TextTooltip
              text={`${values.length > 1
                ? values
                  ?.filter((item, idx) => idx !== 0)
                  .map((item: IChip) => item.label)
                  .join(', ')
                : values.length === 1
                  ? values[0].label
                  : ''
                }`}
            >
              <Chip
                removable={false}
                value={values[1].value}
              >
                +{values.length - 1}
              </Chip>
            </TextTooltip>
          )}
        </Div>
        <Icon20Dropdown
          fill="#99A2AD"
          className={`transition-all ${shown ? 'rotate-180' : ''}`}
        />
      </>
    )
  }
  return (
    <>
      <Text className="text-color-steel-gray-400 pl-2">
        {placeholder}
      </Text>
      <Icon20Dropdown
        fill="#99A2AD"
        className={`transition-all ${shown ? 'rotate-180' : ''}`}
      />
    </>
  );
};

export default memo(Placeholder);
