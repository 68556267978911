import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	ButtonGroup,
	Checkbox,
	ChipsInput,
	Div,
	FormItem,
	Group,
	ModalCard,
	ModalRoot,
	Spinner,
	Text,
	Title,
} from '@vkontakte/vkui';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { NumberInput } from 'components/common/NumberInput';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { IChip } from 'lib/types';
import { ADMIN_DIRECTORIES_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import { typeBlank, typesFieldNameMap } from './constants.typesDirectory';
import { IType } from './types.typesDirectory';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';
import getErrorMessage from 'lib/utils/getErrorMessage';
import { ENDPOINTS } from 'lib/endpoints';

const TypesDirectoryEdit = () => {
	const { data, loading, fetchData, error } = useFetch<IType>();
	const { fetchData: patchData } = useFetch();
	const { directoryId, methodType } = useParams();
	const [typeData, setTypeData] = useState<IType>(typeBlank);
	const { formData, isError, errorItems, handleChange } = useFormItems<IType>({
		initialValues: typeData,
	});
	const { name } = formData;
	const navigate = useNavigate();
	const { setSnackbarContent } = useSnackbar();
	const [openModal, setOpenModal] = useState<string>('');
	const [isNewChecked, setIsNewChecked] = useState<boolean>(false);
	const [newPriority, setNewPriority] = useState<number | null>(null);
	const [newVariantNames, setNewVariantNames] = useState<IChip[]>([]);
	const [newAlias, setNewAlias] = useState<string | null>(null);

	const handleAliasChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const allowedCharacters = /[^a-zA-Z0-9!@#$%^&*()"№;:?\-+=_<>,./|[\]{}']/g;

		const filteredValue = value
			.replace(allowedCharacters, '')
			.replace(/\s+/g, '_');

		setNewAlias(filteredValue);
	};

	const handleDeleteType = () => {
		fetchData({
			method: 'delete',
			path: ENDPOINTS.categoriesId(directoryId),
			onSuccessMessage: 'Успешно удалено',
		})
			.then(() => setOpenModal(''))
			.then(() => navigate(`${ADMIN_DIRECTORIES_ROUTE}/types`));
	};

	const handleSave = async () => {
		if (methodType === 'edit') {
			const diffObject = findObjectsDiffs(formData, typeData);
			const diffKeys = Object.keys(diffObject);
			// eslint-disable-next-line
			const diffData: Record<string, any> = {};

			if (diffKeys.length !== 0) {
				diffKeys.forEach((key) => {
					diffData[key] = formData[key as keyof IType];
				});
			}

			if (typeData?.is_active !== isNewChecked)
				diffData.is_active = isNewChecked;

			if (typeData?.alias !== newAlias) diffData.alias = newAlias;

			if (typeData?.priority !== newPriority) {
				newPriority === null
					? (diffData.priority = null)
					: (diffData.priority = Number(newPriority));
			}

			if (typeData.variant_names && newVariantNames) {
				if (
					!arraysAreEqual(
						typeData.variant_names,
						newVariantNames.map(({ value }) => value),
					)
				) {
					diffData.variant_names = newVariantNames.map(({ value }) => value);
				}
			}

			if (Object.keys(diffData).length > 0) {
				const editPromise = patchData({
					method: 'patch',
					path: ENDPOINTS.categoriesId(directoryId),
					body: replaceEmptyWithNull(diffData),
					onSuccessMessage: 'Изменения сохранены',
				});

				const response = await editPromise;
				const error = response?.errorData?.response?.data;

				if (error?.code) {
					setSnackbarContent({
						type: 'error',
						message: getErrorMessage(error, typesFieldNameMap),
					});
				} else {
					await fetchDataFunction();
				}
			}
		}

		if (methodType === 'addNew') {
			// eslint-disable-next-line
			const { id, ...diffData } = formData;
			diffData.is_active = isNewChecked;
			if (newAlias) diffData.alias = newAlias;

			newPriority === null
				? (diffData.priority = null)
				: (diffData.priority = Number(newPriority));
			diffData.variant_names = newVariantNames.map(({ value }) => value);

			const addPromise = patchData({
				method: 'post',
				path: ENDPOINTS.categories(),
				body: replaceEmptyWithNull(diffData),
				onSuccessMessage: 'Успешно добавлено',
			});

			const response = await addPromise;
			const error = response?.errorData?.response?.data;

			if (error?.code) {
				setSnackbarContent({
					type: 'error',
					message: getErrorMessage(error, typesFieldNameMap),
				});
			} else {
				navigate(`${ADMIN_DIRECTORIES_ROUTE}/types`);
			}
		}
	};

	const fetchDataFunction = () => {
		if (methodType === 'edit') {
			fetchData({ path: ENDPOINTS.categoriesId(directoryId), method: 'get' });
		}
	};

	useEffect(() => {
		fetchDataFunction();
	}, []);

	useEffect(() => {
		if (data) {
			setTypeData(data);
		}
	}, [loading]);

	useEffect(() => {
		setIsNewChecked(typeData.is_active);

		typeData.priority && setNewPriority(typeData.priority);
		typeData.alias && setNewAlias(typeData.alias);

		if (typeData.variant_names) {
			setNewVariantNames(
				typeData.variant_names.map((e) => {
					return { label: e, value: e };
				}),
			);
		}
	}, [typeData]);

	return (
		<>
			{loading ? (
				<Div style={{ width: '100%', height: '400px' }}>
					<Spinner size="medium" className="spinner" />
				</Div>
			) : (
				<>
					<PanelTitle>
						<Title className="text-color-black">
							{methodType === 'edit' ? `Tип` : 'Добавить тип'}
						</Title>
						{methodType === 'edit' && (
							<Title className="inline text-color-steel-gray-500">
								{typeData?.name}
							</Title>
						)}
					</PanelTitle>
					<Group className="custom-scrollbar">
						<EventFormItem
							className="vkui-input"
							top="Название"
							name="name"
							value={name}
							onChange={handleChange}
							placeholder="Введите название типа"
							isRequired={true}
							isError={isError}
							errorItems={errorItems}
							error={error}
						/>
						<FormItem className="vkui-input" top="Приоритет">
							<NumberInput
								value={newPriority}
								onChange={(value: number | null) => setNewPriority(value)}
								name="priority"
								placeholder="Укажите приоритет"
								maxLength={15}
							/>
						</FormItem>
						<FormItem htmlFor="variant_names" top="Варианты наименований">
							<ChipsInput
								id="variant_names"
								placeholder="Наименования не указаны"
								value={newVariantNames || []}
								onChange={setNewVariantNames}
							/>
						</FormItem>
						<EventFormItem
							className="vkui-input"
							top="Псевдоним"
							name="alias"
							value={newAlias || ''}
							onChange={handleAliasChange}
							placeholder="Введите всевдоним"
							isRequired={true}
							isError={isError}
							errorItems={errorItems}
							error={error}
						/>
						<FormItem className="mb-2 ml-3 mt-3 w-32 py-0">
							<Checkbox
								checked={isNewChecked}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									setIsNewChecked(event.target.checked)
								}
							>
								Активно
							</Checkbox>
						</FormItem>
						<Div>
							<ButtonGroup align="right" stretched>
								{methodType === 'edit' ? (
									<>
										<Button
											className="vkui-edit-button-secondary"
											mode="secondary"
											size="m"
											appearance="accent"
											onClick={() => setOpenModal('delete')}
										>
											Удалить
										</Button>
										<Button
											className="vkui-edit-button-primary"
											mode="primary"
											size="m"
											appearance="accent"
											onClick={handleSave}
										>
											Сохранить
										</Button>
									</>
								) : (
									<>
										<Button
											onClick={() =>
												navigate(`${ADMIN_DIRECTORIES_ROUTE}/types`)
											}
											className="vkui-edit-button-secondary"
											mode="secondary"
											size="m"
											appearance="accent"
										>
											Назад
										</Button>
										<Button
											className="vkui-edit-button-primary"
											mode="primary"
											size="m"
											appearance="accent"
											onClick={handleSave}
										>
											Добавить
										</Button>
									</>
								)}
							</ButtonGroup>
						</Div>
					</Group>
				</>
			)}
			<ModalRoot activeModal={openModal}>
				<ModalCard
					id="delete"
					onClose={() => setOpenModal('')}
					className="fixed"
				>
					<Div className="flex flex-col gap-2 p-0">
						<Title className="text-color-black" level="2">
							Удаление типа
						</Title>
						<Text className="text-color-gray-600">Данный тип будет удален</Text>
						<ButtonGroup className="pt-3" align="right" stretched>
							<Button
								className="vkui-edit-button-secondary"
								mode="secondary"
								size="m"
								onClick={() => setOpenModal('')}
							>
								Отмена
							</Button>
							<Button
								className="vkui-edit-button-primary"
								mode="primary"
								size="m"
								onClick={() => handleDeleteType()}
							>
								Удалить
							</Button>
						</ButtonGroup>
					</Div>
				</ModalCard>
			</ModalRoot>
		</>
	);
};

export default TypesDirectoryEdit;
