import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Div,
  Group,
  Title,
  Spinner,
  Button,
  ButtonGroup,
  ModalPage,
  ModalRoot,
  ModalCard,
  Text,
  View,
  Panel,
  PanelHeader,
  Tabs,
  TabsItem,
} from '@vkontakte/vkui';
import { IPersonEdit } from './types.persons';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import useFetch from 'lib/hooks/useFetch';
import PanelTitle from 'components/common/PanelTitle';
import { MODAL_ID, personBlank, personFieldNameMap } from './constants.persons';
import useFormItems from 'lib/hooks/useFormItems';
import { ADMIN_PERSONS_ROUTE, MIN_DIMENSION } from 'lib/constants';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { handleFileSelection, handleImageUrl } from 'lib/utils/imageFile.utils';
import ImageCropper from 'components/common/ImageCropper';
import { formatDateTo } from 'lib/utils/date.utils';
import { getUserRole } from 'lib/utils/authUtils';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import { IChip } from 'lib/types';
import getErrorMessage from 'lib/utils/getErrorMessage';
import { TabId } from './types.persons';
import { tabs } from './constants.persons';
import { PersonsEvents } from './PersonsEvents';
import { PersonsRelatedPersons } from './PersonsRelatedPersons';
import { PersonsPlaces } from './PersonsPlaces';
import { useModal } from 'lib/hooks/useModal';
import { PersonsInfo } from './PersonsInfo';
import { Meta } from 'entity/seoMeta/seoMeta';
import { PersonsSeo } from './PersonSeo';
import { useStateWithSessionStorage } from 'lib/hooks/useStateWithSessionStorage';
import { ENDPOINTS } from 'lib/endpoints';

const PersonsEdit: React.FC = () => {
  const userRole = getUserRole();
  const navigate = useNavigate();
  const [personData, setPersonData] = useState(personBlank);
  const { personId, methodType } = useParams();
  const { setSnackbarContent } = useSnackbar();
  const { data, loading, fetchData } = useFetch<IPersonEdit>();
  const { fetchData: patchData } = useFetch();
  const [newDescription, setNewDescription] = useState<string>('');
  const [hasNewDescription, setHasNewDescription] = useState<boolean>(false);
  const [newBirthday, setNewBirthday] = useState<Date | undefined>(undefined);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [newCover, setNewCover] = useState<string>('');
  const [newCoverOriginal, setNewCoverOriginal] = useState<string>('');
  const [cropError, setCropError] = useState<string>('');
  const [isNewEnabled, setIsNewEnabled] = useState<boolean>(false);
  const [newCommunities, setNewCommunities] = useState<IChip[]>([]);
  const [personMeta, setPersonMeta] = useState<Meta>(null);
  const { formData, handleChange, isError, errorItems } =
    useFormItems<IPersonEdit>({
      initialValues: personData,
    });
  const { id, full_name, vk_page, slug } = formData;
  const [selectedTab, setSelectedTab] = useStateWithSessionStorage<TabId>('selectedPersonTab', 'info');
  const { openModal, closeModal, modalId, modalElement, isModalOpened } =
    useModal();

  const handleSave = async () => {
    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, personData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IPersonEdit];
        });
      }

      if (hasNewDescription && personData.description !== newDescription) {
        diffData.description = newDescription;
      }

      if (new Date(personData.birthday) !== newBirthday) {
        diffData.birthday = newBirthday
          ? formatDateTo({
            date: newBirthday,
            format: 'dayFrom',
          })
          : null;
      }

      if (personData?.is_enabled !== isNewEnabled)
        diffData.is_enabled = isNewEnabled;

      if (personData.preview_url !== newCover) {
        diffData.preview_url = newCover;

        if (
          newCoverOriginal !== '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = newCoverOriginal;
        }

        if (
          newCoverOriginal === '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = '';
        }
      }

      if (personData.communities && newCommunities) {
        if (
          !arraysAreEqual(
            personData.communities.map(({ vk_id }) => vk_id),
            newCommunities.map(({ value }) => value),
          )
        ) {
          diffData.communities = newCommunities
            .map(({ value }) => value)
            .filter((value) => value !== '');
        }
      }

      if (
        personMeta?.title !== personData.meta?.title ||
        personMeta?.description !== personData.meta?.description
      ) {
        diffData.meta = personMeta;
      }

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          method: 'patch',
          path: ENDPOINTS.personsId(personId),
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: getErrorMessage(error, personFieldNameMap),
          });
        } else {
          await fetchDataFunction();
        }
      }
    }

    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;

      if (newBirthday)
        diffData.birthday = formatDateTo({
          date: newBirthday,
          format: 'dayFrom',
        });

      if (newDescription) diffData.description = newDescription;

      diffData.is_enabled = true;

      if (personData.preview_url !== newCover) {
        diffData.preview_url = newCover;

        if (
          newCoverOriginal !== '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = newCoverOriginal;
        }

        if (
          newCoverOriginal === '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = '';
        }
      }

      if (
        personMeta?.title !== personData.meta?.title ||
        personMeta?.description !== personData.meta?.description
      ) {
        diffData.meta = personMeta;
      }

      const addPromise = patchData({
        path: ENDPOINTS.persons(),
        method: 'post',
        body: replaceEmptyWithNull({
          ...diffData,
          communities: newCommunities
            .map(({ value }) => value)
            .filter((value) => value !== ''),
        }),
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: getErrorMessage(error, personFieldNameMap),
        });
      } else {
        navigate(ADMIN_PERSONS_ROUTE);
      }
    }
  };

  const handleIsNewEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsNewEnabled(event.target.checked);
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: ENDPOINTS.personsId(personId), method: 'get' });
    }
  };

  const onCoverSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    handleFileSelection({
      minDimension: MIN_DIMENSION,
      aspectRatio: 1 / 1,
      file: file,
      setCropError: setCropError,
      setImageSrc: setImageSrc,
      setOpenModal: openModal,
      setSnackbarContent: setSnackbarContent,
      setOriginal: setNewCoverOriginal,
    });
  };

  const handleDeletePerson = () => {
    fetchData({
      method: 'delete',
      path: ENDPOINTS.personsId(personId),
      onSuccessMessage: 'Успешно удалено',
    }).then(() => navigate(ADMIN_PERSONS_ROUTE));
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (methodType === 'edit') {
      if (!loading && data) {
        setPersonData(data);
        setPersonMeta(data.meta);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (personData.preview_url_original) {
      handleImageUrl(
        MIN_DIMENSION,
        1 / 1,
        personData.preview_url_original,
        setCropError,
        setImageSrc,
      );
    }

    personData.description && setNewDescription(personData.description);

    setNewCover(personData.preview_url);
    setNewCoverOriginal(personData.preview_url_original);

    personData.is_enabled && setIsNewEnabled(personData.is_enabled);

    if (personData.birthday) {
      const dateObj = new Date(personData.birthday);
      dateObj && setNewBirthday(dateObj);
    }

    if (personData.communities) {
      setNewCommunities(
        personData.communities.map(({ vk_id }) => {
          return { label: vk_id, value: vk_id };
        }),
      );
    }
  }, [personData]);

  useEffect(() => {
    newDescription === '<p><br></p>' && setNewDescription('');
  }, [newDescription]);

  const handleAddCommunity = () => {
    const newAllCommunities = [
      ...newCommunities,
      {
        label: '',
        value: '',
      },
    ];

    setNewCommunities(newAllCommunities);
  };

  const handleChangeCommunity = (value: string, index: number) => {
    const updatedCommunities = [...newCommunities];

    updatedCommunities[index] = {
      label: value,
      value: value,
    };

    setNewCommunities(updatedCommunities);
  };

  const handleDeleteCommunity = (index: number) => {
    const updatedCommunities = newCommunities.filter((_, i) => i !== index);
    setNewCommunities(updatedCommunities);
  };

  const footerFormButton = (
    <Div>
      <ButtonGroup align="right" stretched>
        {methodType === 'edit' ? (
          <>
            <Button
              className="vkui-edit-button-secondary"
              mode="secondary"
              size="m"
              appearance="accent"
              onClick={() => openModal('delete')}
            >
              Удалить
            </Button>
            <Button
              className="vkui-edit-button-primary"
              mode="primary"
              size="m"
              appearance="accent"
              onClick={handleSave}
            >
              Сохранить
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => navigate(`${ADMIN_PERSONS_ROUTE}`)}
              className="vkui-edit-button-secondary"
              mode="secondary"
              size="m"
              appearance="accent"
            >
              Назад
            </Button>
            <Button
              className="vkui-edit-button-primary"
              mode="primary"
              size="m"
              appearance="accent"
              onClick={handleSave}
            >
              Добавить
            </Button>
          </>
        )}
      </ButtonGroup>
    </Div>
  );

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">Детали персоны</Title>
            <span className="text-3xl text-color-black">-</span>
            <Title className="inline text-color-steel-gray-500">
              {full_name}
            </Title>
          </PanelTitle>
          <View activePanel="panel" className="vkui-tabs">
            <Panel id="panel">
              <PanelHeader className="relative">
                <Tabs layoutFillMode="shrinked" className="flex pl-5">
                  {tabs.map(({ title, id }) => (
                    <TabsItem
                      key={id}
                      onClick={() => setSelectedTab(id)}
                      selected={selectedTab === id}
                    >
                      {title}
                    </TabsItem>
                  ))}
                </Tabs>
              </PanelHeader>
              {selectedTab === 'info' && (
                <PersonsInfo
                  id={id}
                  slug={slug}
                  methodType={methodType}
                  newCover={newCover}
                  isError={isError}
                  errorItems={errorItems}
                  imageSrc={imageSrc}
                  fullName={full_name}
                  handleChange={handleChange}
                  onCoverClick={() => openModal('coverPreview')}
                  onCoverDelete={() => openModal('deleteCover')}
                  onCropClick={() => openModal('crop')}
                  onCoverSelect={onCoverSelect}
                  onAddCommunity={handleAddCommunity}
                  onDeleteCommunity={handleDeleteCommunity}
                  newBirthday={newBirthday}
                  vkPage={vk_page}
                  setNewBirthday={setNewBirthday}
                  isModalOpened={isModalOpened}
                  newCommunities={newCommunities}
                  onChangeCommunity={handleChangeCommunity}
                  setNewDescription={setNewDescription}
                  setHasNewDescription={setHasNewDescription}
                  newDescription={newDescription}
                  isNewEnabled={isNewEnabled}
                  handleIsNewEnabledChange={handleIsNewEnabledChange}
                  userRole={userRole}
                  footerFormButton={footerFormButton}
                />
              )}
              {selectedTab === 'seo' && (
                <Group>
                  <PersonsSeo
                    meta={personMeta}
                    setMeta={setPersonMeta}
                    footerForm={footerFormButton}
                  />
                </Group>
              )}
              {selectedTab === 'events' && (
                <Group>
                  <PersonsEvents
                    openModal={openModal}
                    closeModal={closeModal}
                  />
                </Group>
              )}
              {selectedTab === 'persons' && (
                <Group>
                  <PersonsRelatedPersons />
                </Group>
              )}
              {selectedTab === 'places' && (
                <Group>
                  <PersonsPlaces
                    openModal={openModal}
                    closeModal={closeModal}
                  />
                </Group>
              )}
            </Panel>
          </View>
        </>
      )}
      <ModalRoot activeModal={modalId}>
        <ModalPage
          className="fixed z-20"
          id="crop"
          onClose={closeModal}
          settlingHeight={100}
          height={'70%'}
          size={'l'}
        >
          {cropError && <p className="text-red-400">{cropError}</p>}
          <ImageCropper
            minDimension={MIN_DIMENSION}
            aspectRatio={1 / 1}
            imageSrc={imageSrc}
            setOpenModal={openModal}
            setState={setNewCover}
          />
        </ModalPage>
        <ModalCard className="fixed" id="deleteCover" onClose={closeModal}>
          <Div className="flex flex-col gap-2 p-0">
            <Title className="mb-2 text-color-black" level="2">
              Удаление обложки
            </Title>
            <Text className="mb-6 text-color-gray-600">
              Вы действительно хотите удалить обложку?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={closeModal}
              >
                Назад
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-error"
                size="m"
                onClick={() => {
                  setNewCover('');
                  setNewCoverOriginal('');
                  closeModal();
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="delete" onClose={closeModal} className="fixed">
          <Div className="flex flex-col gap-2 p-0">
            <Title className="text-color-black" level="2">
              Удаление персоны
            </Title>
            <Text className="text-color-gray-600">
              Данная персона будет удалена
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={closeModal}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => {
                  closeModal();
                  handleDeletePerson();
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalPage
          id="coverPreview"
          onClose={closeModal}
          settlingHeight={100}
          className="fixed flex max-h-fit flex-col items-center justify-center"
          size={1200}
          dynamicContentHeight={true}
        >
          <Div className="relative flex max-h-fit flex-col items-center justify-center">
            <img
              src={newCover}
              className="max-h-[640px] rounded-3xl object-contain p-4"
            />
          </Div>
        </ModalPage>
        <ModalPage
          id={MODAL_ID.addEvents}
          onClose={closeModal}
          size={1200}
          dynamicContentHeight={true}
          className="fixed"
        >
          {modalElement}
        </ModalPage>
        <ModalPage
          id={MODAL_ID.addPlaces}
          onClose={closeModal}
          size={1200}
          dynamicContentHeight={true}
          className="fixed"
        >
          {modalElement}
        </ModalPage>
      </ModalRoot>
    </>
  );
};

export default PersonsEdit;
