import React, { memo, useCallback } from 'react';

import useFetch from 'lib/hooks/useFetch';
import { PaginationApiResponse } from 'lib/api/api';
import { IFilters } from 'lib/hooks/useParams';
import { ChipsSelectFilter, IFetchParams } from '../ChipsSelectTemplate';
import { fetchData } from 'lib/api/fetchData';
import { ENDPOINTS } from 'lib/endpoints';

const PAGE_SIZE_PLACES = 24;
const FILTER_KEY_PLACES = "place_id";

interface IChipsSelectPlaces {
    // eslint-disable-next-line
    idFromQuery: any;
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const ChipsSelectPlaces = <T extends { id: string; name: string }>({
    idFromQuery,
    setFilters,
}: IChipsSelectPlaces) => {
    const fetchPlaceById = useCallback((id: string) => fetchData<T>({
        method: 'get',
        path: ENDPOINTS.placesId(id),
    }), []);

    const {
        fetchData: fetchPlaces,
        data: placesData,
        loading: placesLoading,
        error: placesError,
    } = useFetch<PaginationApiResponse<T[]>>();

    const fetchList = useCallback(({ page, searchQuery }: IFetchParams = {}) => {
        fetchPlaces({
            method: 'get',
            path: ENDPOINTS.places(),
            params: {
                page: page ?? 0,
                page_size: PAGE_SIZE_PLACES,
                q: searchQuery,
            }
        });
    }, [fetchPlaces]);

    return (
        <ChipsSelectFilter
            top="Место проведения"
            placeholder="Выберите места проведения"
            filterKey={FILTER_KEY_PLACES}
            idFromQuery={idFromQuery}
            setFilters={setFilters}
            fetchById={fetchPlaceById}
            data={placesData}
            loading={placesLoading}
            error={placesError}
            fetchList={fetchList}
        />
    );
};

export default memo(ChipsSelectPlaces);
