import React, { memo, useCallback, useMemo } from 'react';

import useFetch from 'lib/hooks/useFetch';
import { PaginationApiResponse } from 'lib/api/api';
import { IFilters } from 'lib/hooks/useParams';
import { ChipsSelectFilter, IFetchParams } from '../ChipsSelectTemplate';
import { fetchData } from 'lib/api/fetchData';
import { ENDPOINTS } from 'lib/endpoints';

const PAGE_SIZE_LABELS = 24;
const FILTER_KEY_LABEL_TOP = "label_top_id";
const FILTER_KEY_LABEL_BOTTOM = "label_bottom_id";

interface IChipsSelectLabels {
    // eslint-disable-next-line
    idFromQuery: any;
    setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
    type: 'event' | 'price';
}

const ChipsSelectLabels = <T extends { id: string; name: string }>({
    idFromQuery,
    setFilters,
    type,
}: IChipsSelectLabels) => {
    const fetchLabelById = useCallback((id: string) => fetchData<T>({
        method: 'get',
        path: ENDPOINTS.labelId(id),
    }), []);

    const {
        fetchData: fetchLabels,
        data: labelsData,
        loading: labelsLoading,
        error: labelsError,
    } = useFetch<PaginationApiResponse<T[]>>();

    const fetchList = useCallback(({ page, searchQuery }: IFetchParams = {}) => {
        fetchLabels({
            method: 'get',
            path: ENDPOINTS.label(),
            params: {
                page: page ?? 0,
                page_size: PAGE_SIZE_LABELS,
                q: searchQuery,
                type,
                is_active: true,
            }
        });
    }, [fetchLabels, type]);

    const filterKey = useMemo(() =>
        type === 'event' ? FILTER_KEY_LABEL_TOP : FILTER_KEY_LABEL_BOTTOM
        , [type]
    )

    const top = useMemo(() =>
        type === 'event' ? 'Лейбл мероприятия' : 'Лейбл цены'
        , [type]
    )

    return (
        <ChipsSelectFilter
            top={top}
            placeholder="Выберите лейбл"
            filterKey={filterKey}
            idFromQuery={idFromQuery}
            setFilters={setFilters}
            fetchById={fetchLabelById}
            data={labelsData}
            loading={labelsLoading}
            error={labelsError}
            fetchList={fetchList}
        />
    );
};

export default memo(ChipsSelectLabels);
