import { fetchData, FetchDataResponse } from "lib/api/fetchData";
import { IChip } from "lib/types";
import { useEffect } from "react";

interface IUseGetChipById<T> {
    // eslint-disable-next-line
    idFromQuery: any;
    setSelectedChips: React.Dispatch<React.SetStateAction<IChip[]>>;
    fetchById: (id: string) => Promise<FetchDataResponse<T>>;
}

//Хук для получения IChip из id в query параметрах
export const useGetChipById = <T extends { id: string; name: string }>({ idFromQuery, setSelectedChips, fetchById }: IUseGetChipById<T>) => {
    const getNameById = async (ids: IChip[]) => {
        if (ids?.length > 0) {
            const responses = await Promise.all(
                ids.map((id) => fetchById(id.value))
            );

            const chips = responses
                .map((response) => {
                    if (response.isError) {
                        return null;
                    }

                    return {
                        label: response.data.name,
                        value: response.data.id
                    }
                })
                .filter((chip) => Boolean(chip)) as IChip[]

            setSelectedChips((prev) => {
                const newArr = [
                    ...prev,
                    ...chips,
                ];

                const uniqueArr = Array.from(
                    new Map(newArr.map((item) => [item.value, item])).values(),
                );

                return uniqueArr;
            });

        }
    };

    useEffect(() => {
        getNameById(idFromQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}