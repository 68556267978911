import useFetch from 'lib/hooks/useFetch';
import { useParams } from 'react-router-dom';
import { IPersonPlace, IPersonEvent, PersonRelatedPerson } from '../types.persons';
import * as React from 'react';
import { ENDPOINTS, PersonEntityType } from 'lib/endpoints';

type DataMap = {
  place: IPersonPlace;
  event: IPersonEvent;
  person: PersonRelatedPerson;
}

export const useFetchPersonData = <T extends PersonEntityType>(entityType: T) => {
  const { personId = '' } = useParams<{ personId: string }>();
  const { fetchData: fetchDataBase, loading, data } = useFetch<DataMap[T][]>();

  const fetchData = React.useCallback(() => {
    fetchDataBase({
      path: ENDPOINTS.personsEntities(personId),
      params: {
        entity_type: entityType
      }
    })
  }, [personId, fetchDataBase, entityType]);

  React.useEffect(() => {
    fetchData();
  }, []);

  return { loading, data, ids: data?.map((value) => value.entity.id) || [], fetchData }
};