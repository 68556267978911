import React, { useCallback, useEffect, useState } from 'react';
import { IChip } from 'lib/types';
import { debounce } from 'lib/utils/debounce';
import { ChipsSelectUI } from './ChipsSelectUI';

export interface IFetchParams { page?: number, searchQuery?: string };

interface IChipsSelect {
  placeholder?: string;
  options: IChip[];
  setOption: React.Dispatch<React.SetStateAction<IChip[]>>;
  values: IChip[];
  setValues: React.Dispatch<React.SetStateAction<IChip[]>>;
  fetch: (params?: IFetchParams) => void;
  nextPage?: number;
  error?: boolean;
  loading?: boolean;
}

const ChipsSelect = ({
  placeholder,
  options,
  setOption,
  values,
  setValues,
  nextPage,
  error,
  loading,
  fetch,
}: IChipsSelect) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [shownPopup, setShownPopup] = useState(false);

  const onChangeOption = useCallback((item: IChip) => {
    setValues((prev) => {
      const temp = prev.filter((val) => val.value !== item.value);

      if (temp.length === prev.length) {
        temp.push(item);
      }

      return temp;
    });
  }, [setValues]);

  const fetchDebounce = useCallback(debounce((params: IFetchParams) => {
    fetch(params)
    setOption([]);
  }), [fetch]);

  const onChangeSearch = useCallback((newSearchQuery: string) => {
    setSearchQuery(newSearchQuery)
    fetchDebounce({ page: 0, searchQuery: newSearchQuery })
  }, [fetchDebounce]);

  const onLoadMore = useCallback(() => {
    if (!loading) {
      fetch({ page: nextPage ?? 0, searchQuery })
    }
  }, [fetch, loading, nextPage, searchQuery]);

  useEffect(() => {
    if (!shownPopup) {
      setOption([]);
      fetch();
    }
  }, [fetch, setOption, setValues, shownPopup])

  useEffect(() => {
    onLoadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSpinner = !error && (Boolean(nextPage) || (loading && !(options.length - values.length)));

  const showNotFound = !loading && !(options.length - values.length);

  return (
    <ChipsSelectUI
      placeholder={placeholder}
      options={options}
      values={values}
      onChangeOption={onChangeOption}
      onLoadMore={onLoadMore}
      searchQuery={searchQuery}
      onChangeSearch={onChangeSearch}
      shownPopup={shownPopup}
      setShownPopup={setShownPopup}
      showNotFound={showNotFound}
      showSpinner={showSpinner}
    />
  );
};

export default ChipsSelect;
