import * as React from 'react';
import useFetch from 'lib/hooks/useFetch';
import { IEventPerson } from './types.events';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { PersonSelection } from 'components/complex/PersonSelection';
import { ENDPOINTS } from 'lib/endpoints';

interface IEventPersonsProps {
  currentPersons: IEventPerson[];
  eventId: string | undefined;
}

const EventPersons: React.FC<IEventPersonsProps> = ({
  currentPersons,
  eventId,
}) => {
  const navigate = useNavigate();
  const { setSnackbarContent } = useSnackbar();
  const { fetchData: patchData, loading: patching } = useFetch();

  const handleSave = async (personIds: string[]) => {
    try {
      await patchData({
        method: 'patch',
        path: ENDPOINTS.eventsId(eventId),
        body: { persons: personIds },
        onSuccessMessage: 'Персона добавлена',
      });
      navigate(0);
    } catch (error) {
      setSnackbarContent({
        type: 'error',
        message: 'Ошибка при добавлении персоны',
      });
    }
  };

  return (
    <PersonSelection
      currentPersons={currentPersons}
      onApply={handleSave}
      applying={patching}
    />
  );
};

export default EventPersons;
