import { ENDPOINTS, PersonEntityType } from "lib/endpoints";
import useFetch from "lib/hooks/useFetch";
import * as React from 'react';
import { useParams } from "react-router-dom";

type PatchDataConfig = {
  entityType: PersonEntityType;
  /**
   * Массив айдишек сущностей, связанных с персоной
   */
  entities: string[];
  onSuccess?: VoidFunction;
};

const MAP_TO_FIELDS: Record<PersonEntityType, string> = {
  event: 'events',
  place: 'places',
  person: 'persons',
};

export const usePatchPersonData = () => {
  const { fetchData, loading } = useFetch();
  const { personId = '' } = useParams<{ personId: string }>();

  const patchData = React.useCallback(async ({ entities, entityType, onSuccess }: PatchDataConfig) => {
    const response = await fetchData({
      method: 'patch',
      path: ENDPOINTS.personsId(personId),
      body: { [MAP_TO_FIELDS[entityType]]: entities },
    });

    // Пустой респонс возвращается только в случае успеха
    if (!response) {
      onSuccess?.();
    }
  }, [personId]);

  return { patching: loading, patchData };
};