import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IChip, IOrdering, IRate } from '../types';
import { cleanSearchQuery, escapeDoubleQuotes } from 'lib/utils/parseFunction';
import { getClearQueryParam } from 'lib/utils/getQueryParam';
import { parseSortingParam } from 'lib/utils/parseSortingParam';

//todo: сделать строгую типизацию для всех параметров
export type IFilters = Record<
  string,
  Date | boolean | string | string[] | IRate | IChip[] | (Date[] | undefined)
>;

const sortState = ['desc', 'asc'];

//todo: что-то непонятное, думаю надо переписать
const useParams = (initialFilters: IFilters) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = useState<IFilters>({ ...initialFilters });
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [sorting, setSorting] = useState<IOrdering>({ key: '', value: '' });
  const [sortUrl, setSortUrl] = useState<Record<string, string>>({
    ordering: '',
  });
  const [inputValue, setInputValue] = useState('');

  const initialPageParamClear =
    Number(getClearQueryParam(window.location.search, 'page')) < 1
      ? 1
      : Number(getClearQueryParam(window.location.search, 'page'));

  const [currentPage, setCurrentPage] = useState<number>(
    Number(initialPageParamClear),
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setFilters({ ...filters, search: event.target.value });
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type?: 'from' | 'to',
  ) => {
    const { name, value } = event.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type ? { ...(prevFilters[name] as IRate), [type]: value } : value,
    }));
  };

  const handleChipsSelectChange = (name: string, values: IChip[]) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: [...values] }));
  };

  const handletoggleFilters = () => {
    setOpenFilters((prevOpenFilters) => !prevOpenFilters);
  };

  const setSortingFunc = (value: string) => {
    const newValue =
      value === sorting.key
        ? sortState.filter((item) => item !== sorting.value)[0]
        : sortState[0];

    setSorting({
      key: value,
      value: newValue,
    });
  };

  const addFiltersAndUpdateUrl = () => {
    const { ...newFilters } = filters;
    newFilters.page = '0';
    setCurrentPage(1);

    const queryString = Object.entries({ ...newFilters })
      .filter(([key, value]) => {
        if (newFilters[key] === '') {
          return false;
        }

        if (Array.isArray(value)) {
          return value.length > 0;
        }

        return true;
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const arrayValue = value as IChip[];
          return `${key}=[${arrayValue
            .map((item) =>
              item.value !== undefined
                ? `"${encodeURIComponent(item.value)}"`
                : item,
            )
            .join(',')}]`;
        }

        if (typeof value === 'object') {
          const rangeValue = value as IRate;
          return `${key}={${Object.entries(rangeValue)
            .filter(([, value]) => value)
            .map(([key, value]) => `"${key}":"${value}"`)
            .join(',')}}`;
        }

        const cleanedValue = cleanSearchQuery(value as string);
        const escapedValue = escapeDoubleQuotes(
          cleanedValue ? cleanedValue : '',
        );
        const encodedValue = encodeURIComponent(escapedValue);
        return `${key}="${encodedValue}"`;
      })
      .join('&');

    navigate(`?${queryString}`);
  };

  const parseJSON = (input: string): ReturnType<typeof JSON.parse> | null => {
    try {
      return JSON.parse(input);
    } catch (error) {
      navigate('');
      return null;
    }
  };

  const parseFiltersUrl = () => {
    if (location.search) {
      const queryParams = location.search.substring(1).split('&');

      let updatedFilters = { ...filters, ...sortUrl };

      queryParams.forEach((param) => {
        const [key, value] = param.split('=');

        updatedFilters = {
          ...updatedFilters,
          [key]: Array.isArray(parseJSON(decodeURIComponent(value)))
            ? parseJSON(decodeURIComponent(value)).map(
              (item: string | number) => ({
                label: item,
                value: item,
              }),
            )
            : parseJSON(decodeURIComponent(value)),
        };
      });
      setFilters(updatedFilters);
      setInputValue(updatedFilters['search'] as string);
    }
  };

  const updateSortingUrl = () => {
    const queryParams = location.search.substring(1).split('&');

    if (queryParams.length > 0 && queryParams[0] !== '') {
      try {
        let updatedFilters = {};

        queryParams.forEach((param) => {
          const [key, value] = param.split('=');

          updatedFilters = {
            ...updatedFilters,
            [key]: Array.isArray(parseJSON(decodeURIComponent(value)))
              ? parseJSON(decodeURIComponent(value)).map(
                (item: string | number) => ({
                  label: item,
                  value: item,
                }),
              )
              : parseJSON(decodeURIComponent(value)),
          };
        });

        let newFilters;
        if (sorting.value) {
          newFilters = {
            ...updatedFilters,
            ordering: `${sorting.key}_${sorting.value}`,
          };
        } else {
          newFilters = {
            ...updatedFilters,
          };
        }

        const queryString = Object.entries(newFilters)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              const arrayValue = value as IChip[];
              return `${key}=[${arrayValue
                .map((item) =>
                  item.value !== undefined
                    ? `"${encodeURIComponent(item.value)}"`
                    : item,
                )
                .join(',')}]`;
            }

            if (typeof value === 'object') {
              const rangeValue = value as IRate;
              return `${key}={${Object.entries(rangeValue)
                .filter(([, value]) => value)
                .map(
                  ([key, value]) => `"${key}":"${encodeURIComponent(value)}"`,
                )
                .join(',')}}`;
            }

            if (key === 'q') {
              return `${key}="${encodeURIComponent(
                escapeDoubleQuotes((value as string) ? (value as string) : ''),
              )}"`;
            }

            return `${key}="${encodeURIComponent(value ? value : '')}"`;
          })
          .join('&');

        navigate(`?${queryString}`);
      } catch (error) {
        console.error('Error processing query parameters:', error);
      }
    } else {
      if (sorting.key && sorting.value) {
        navigate(`?ordering="${sorting.key}_${sorting.value}"`);
      }
    }
  };

  const reset = useCallback(() => {
    setFilters({
      ...initialFilters,
      search: '',
    });
    setInputValue('');
    setSorting({ key: '', value: '' });
    setCurrentPage(1);
    navigate('');
  }, [setFilters, setInputValue, setSorting, setCurrentPage, navigate]);

  useEffect(() => {
    if (location.search) {
      const queryParams = location.search.substring(1).split('&');

      parseFiltersUrl();

      if (queryParams.length === 1 && location.search.includes('page')) {
        setOpenFilters(false);
      } else {
        setOpenFilters(true);
      }
    }
  }, [location.search]);

  useEffect(() => {
    updateSortingUrl();
  }, [sorting, sortUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderingParam = queryParams.get('ordering');

    if (orderingParam) {
      const cleanedValue = orderingParam.replace(/^"(.*)"$/, '$1');
      setSorting(parseSortingParam(cleanedValue));
      setSortUrl({ ordering: cleanedValue });
    }
  }, [location.search]);

  return {
    filters,
    currentPage,
    setCurrentPage,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    handleChipsSelectChange,
    openFilters,
    handletoggleFilters,
    inputValue,
    handleInputChange,
    parseFiltersUrl,
    setSortingFunc,
    sorting,
  };
};

export default useParams;
