import React, { ChangeEvent, useCallback, useMemo } from 'react';
import {
  Div,
  Input,
  Text,
} from '@vkontakte/vkui';
import { IChip } from 'lib/types';
import {
  Icon20Search,
  Icon28InfoCircleOutline,
} from '@vkontakte/icons';
import { Popover } from '@vkontakte/vkui/dist/components/Popover/Popover';
import { ChipCheckbox } from './ChipCheckbox';
import { Placeholder } from './Placeholder';
import { SpinnerWithInView } from 'components/common/SpinnerWithInView';

interface IChipsSelectUI {
  placeholder?: string;
  options: IChip[];
  values: IChip[];
  onChangeOption: (item: IChip) => void;
  onLoadMore?: VoidFunction;
  searchQuery: string;
  onChangeSearch: (newSearch: string) => void;
  shownPopup: boolean;
  setShownPopup: (shown: boolean) => void;
  showNotFound: boolean;
  showSpinner?: boolean;
}

const ChipsSelectUI = ({
  placeholder,
  options,
  values,
  onChangeOption,
  onLoadMore,
  searchQuery,
  onChangeSearch,
  shownPopup,
  setShownPopup,
  showNotFound,
  showSpinner,
}: IChipsSelectUI) => {

  const optionWithoutValues = useMemo(() => {
    const valueValues = values.map((val) => val.value);
    return options.filter((option) => !valueValues.includes(option.value))
  }, [options, values]);

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(event.currentTarget?.value ?? '');
  }, [onChangeSearch]);

  return (
    <div
      className="cursor-pointer"
    >
      <Popover
        action="click"
        shown={shownPopup}
        onShownChange={setShownPopup}
        className="overflow-y-hidden before:content-[]"
        content={
          <Div className="p-0 m-0 w-96">
            <Input
              style={{ outline: 'none' }}
              className="chips-input input-style bg-color-white p-0 m-0"
              placeholder="Поиск"
              defaultValue={searchQuery}
              onChange={onChangeInput}
              after={<Icon20Search />}
            />
            <Div
              className="custom-scrollbar p-0 max-h-[380px] overflow-y-scroll"
            >
              <Div
                className="mx-1 px-3 py-0 border-b-1 border-solid border-color-form-border"
              >
                {values.map((item) => (
                  <ChipCheckbox
                    key={item.value}
                    checked={true}
                    onChange={onChangeOption}
                    chip={item}
                  />
                ))}
              </Div>
              <Div className="mx-1 px-3 py-0">
                {
                  optionWithoutValues.map((item) => (
                    <ChipCheckbox
                      key={item.value}
                      checked={false}
                      onChange={onChangeOption}
                      chip={item}
                    />
                  ))
                }
                {
                  showNotFound && (
                    <Div className="p-5 flex justify-center items-center flex-col gap-2">
                      <Icon28InfoCircleOutline fill="#5181B8" />
                      <Text className="text-lg foWnt-semibold text-color-blue-400">
                        Ничего не найдено
                      </Text>
                    </Div>)
                }
                {
                  showSpinner && (
                    <SpinnerWithInView
                      className="mt-[12px] mb-[12px]"
                      size="regular"
                      callback={onLoadMore}
                      once={false}
                    />
                  )
                }
              </Div>
            </Div>
          </Div>
        }
      >
        <Div
          className="flex items-center justify-between bg-color-steel-gray-20 rounded-lg h-10"
          style={{ outline: '0.5px solid #d9dadc' }}
        >
          <Placeholder
            placeholder={placeholder}
            values={values}
            onChangeOption={onChangeOption}
            shown={shownPopup}
          />
        </Div>
      </Popover >
    </div >
  );
};

export default ChipsSelectUI;
